import { useMemo } from "react";
import {SleepGudelineTable} from "../report/Table"
import { styled } from '@mui/system';

type Props = {
  lang: 'en'|'ja';
  small?: boolean;
}

export const GoodSleepCondition = (props: Props) => {
  const { lang, small } = props;
  const tableHeaderJa = ['項目', '解説'];
  const tableHeaderEn = ['Item', 'Explanation'];
  const tableRowJa = useMemo(() => {
    return [
      ['全体の方向性', '● 個人差を踏まえつつ、日常的に質・量ともに充分な睡眠を確保し、心身の健康を保持する。'],
      ['高齢者', '● 長い床上時間が健康リスクとなるため、床上時間が8時間以上にならないことを目安に、必要な睡眠時間を確保する。\n● 食生活や運動等の生活習慣や寝室や睡眠環境を見直して、睡眠休養感を高める。\n● 長い昼寝は夜間の良眠を妨げるため、日中は長時間の昼寝を避け、活動的に過ごす。'],
      ['成人', '● 適正な睡眠時間には個人差があるが、6時間以上目安として必要な睡眠時間を確保する。\n● 食生活や運動等の生活習慣、寝室の睡眠環境と見直して、睡眠休養感を高める。\n● 睡眠の不調・睡眠休養感の低下がある場合は、生活習慣の改善を図ることが重要であるが、病気が潜んでいる可能性にも留意する。'],
      ['子供', '● 小学生は9～12時間、中学・高校生は8～10時間を参考に睡眠時間を確保する。\n● 朝は太陽の光を浴びて、朝食をしっかり摂り、日中は運動をして、夜ふかしの習慣化を避ける。']
    ];
  }, []);
  
  const tableRowEn = useMemo(() => {
    return [
      ['Overall direction', '● To maintain physical and mental health, you need to ensure they get sufficient sleep, in terms of both quality and quantity, considering individual differences.'],
      ['Elderly', '● Since spending a long time in bed can be a health risk, ensure you get the sleep you need without spending more than 8 hours.\n● To get enough rest through sleep, review your lifestyle habits, such as diet and exercise, as well as your bedroom and sleeping environment.\n● Long naps during the day can interfere with good sleep at night, so avoid them and stay active during the day.'],
      ['Adults', '● The appropriate amount of sleep varies among individuals; aim to get at least 6 hours.\n● To get enough rest through sleep, review your lifestyle habits, such as diet and exercise, as well as your bedroom and sleeping environment.\n● If you have sleep problems or decreased restfulness, try to improve your lifestyle habits. However, it is also important to be aware that there may be an underlying illness behind your symptoms.'],
      ['Children', '● Ensure that elementary school children get 9 to 12 hours of sleep and middle and high school students get 8 to 10 hours.\n● In the morning, get some sunlight and have breakfast. Exercise during the day and avoid getting into the habit of staying up late.']
    ];
  }, []);
  return (
    <>
    <SleepGudelineTable
        headers={lang === "ja" ? tableHeaderJa : tableHeaderEn}
        rows={lang === "ja" ? tableRowJa : tableRowEn}
        lang={lang}
        small={small}
      />
      <Caption>{lang === "ja" ? '※生活習慣や環境要因等の影響により、身体の状況等の個人差が大きいことから、高齢者、成人、子供について特定の年齢で区切ることが適当でなく、個人の状況に応じて取組を行うことが重要であると考えられる。' : '※Given the significant individual differences in physical conditions due to lifestyle habits and environmental factors, it is not appropriate to categorize older people, adults, and children strictly by age. It is essential to tailor approaches based on individual circumstances.'}</Caption>
    </>
  )
}
const Caption = styled('div')`  
  font-size: 0.9rem;
  color: #4B4B4B;
  margin-top: 8px;
  margin-bottom: 8px;
`;  
