import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { SimpleGrid } from '../atoms/Grid';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import MuiTextField, { TextFieldProps } from '@mui/material/TextField';
import { InputAdornment, FormHelperText } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Avatar from '@mui/material/Avatar';
import { isMatchValidation } from '../../utils/Validation';
import Typography from '@mui/material/Typography';
import { SxProps, Theme } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';

const smWidth = '375px';
const smTop = '45px';

export const TextField = (props: any) => {
  return <StyledTextField {...props} variant='outlined' />;
};

type CustomTextFieldProps = {
  setFunction: any;
  name: string;
  name2?: string;
  name3?: string;
  value: string | number | null;
  validationType?: string;
  required?: boolean;
  propsHelperMessage?: string;
  readOnly?: boolean;
  placeholder?: string;
  helperText1?: string;
  helperText2?: string;
  maxLength?: string;
  rows?: number;
  gridSize?: number;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  endInputAdorment?: any;
  width?: number,
}

type CustomPasswordTextFieldProps = {
  setFunction: any;
  name: string;
  name2?: string;
  name3?: string;
  value: string | number | null;
  validationType?: string;
  required?: boolean;
  propsHelperMessage?: string;
  readOnly?: boolean;
  placeholder?: string;
  helperText1?: string;
  helperText2?: string;
  maxLength?: string;
  rows?: number;
  gridSize?: number;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  endInputAdorment?: any;
  width?: number,
  error: boolean
}

/*const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));*/

/*const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    borderRadius: '5px'
  },
  helperText: {
    color: 'red',
  },
  photo: {
    cursor: 'pointer',
    color: '#BABAB9',
  },
  doneIcon: {
    color: '#27AE60'
  },
  errorIcon: {
    color: '#DC3545'
  },
  cancelIcon: {
    cursor: 'pointer',
    color: '#DADADA'
  },
}));*/

export const SingleTextPasswdInput = (props: CustomPasswordTextFieldProps) => {
  //const classes = useStyles();
  const {
    setFunction,
    name,
    value,
    required,
    validationType,
    propsHelperMessage,
    placeholder,
    helperText1,
    helperText2,
    maxLength,
    disabled,
    width,
    error
  } = props;
  const [helperMessage, setHelperMessage] = useState('');
  const [validationError, setValidationError] = useState(false);
  //const [valueLength, setValueLength] = useState(value ? value.length : 0);
  const [inputEvent, setInputEvent] = useState(false);
  const [passwdValues, setPasswdValues] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });
  const onChange = (e: any) => {
    if (setFunction) {
      let data = e.target.value;
      if (validationType) {
        if (!isMatchValidation(validationType || '', data)) {
          setHelperMessage(propsHelperMessage || '');
          setValidationError(true);
        } else {
          setHelperMessage('');
          setValidationError(false);
        }
      }
      setFunction(data);
      //setValueLength(data?.length);
    }
  };
  const handleClickShowPassword = () => {
    setPasswdValues({
      ...passwdValues,
      showPassword: !passwdValues.showPassword,
    });
  };
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };
  const onFocus = () => {
    setInputEvent(true);
  };
  const onBlur = () => {
    // フォーカス解除を少し遅らせることで、
    // clearText関数の実行を待つ
    setTimeout(() => {
      setInputEvent(false);
    }, 200);
  };
  useEffect(() => {
    setHelperMessage(helperMessage);
    //setValueLength(value ? value.length : 0);
  }, [helperMessage, value, inputEvent]);
  return (
    <SimpleGrid item xs={props.gridSize}>
      <Div>
        <StyledForm>
          {<Title data-required={required} variant='body1' gutterBottom>
            {name}
          </Title>}
          <StyledOutlinedInput
            name={name}
            onFocus={onFocus}
            onBlur={onBlur}
            error={error}
            sx={{ display: "flex", width: width || smWidth, /*marginTop: '20px' */ }}
            //classes={{ root: classes.root }}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            required={required}
            disabled={disabled}
            type={passwdValues?.showPassword ? 'text' : 'password'}
            endAdornment={
              <>
                <InputAdornment position='end'>
                  <CustomIconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {passwdValues?.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </CustomIconButton>
                </InputAdornment>
              </>
            }
            inputProps={{ maxLength: maxLength }}
          />
          <HelperWrap>
            {helperText1 ? (
              <StyledFormHelper>{helperText1}</StyledFormHelper>
            ) : null}
            {helperText2 ? (
              <StyledFormHelper>{helperText2}</StyledFormHelper>
            ) : null}
          </HelperWrap>
        </StyledForm>
      </Div>
    </SimpleGrid>
  );
};

/*const useStyles: any = makeStyles(() => ({
  root: {
    width: '100%',
    borderRadius: '5px'
  },
  helperText: {
    color: 'red',
  },
  photo: {
    cursor: 'pointer',
    color: '#BABAB9',
  },
  doneIcon: {
    color: '#27AE60'
  },
  errorIcon: {
    color: '#DC3545'
  },
  cancelIcon: {
    cursor: 'pointer',
    color: '#DADADA'
  },
})
);*/

/*const formLabelsTheme = createTheme({
  //typography: {
  //  useNextVariants: true,
  //  suppressDeprecationWarnings: true
  //},
  //palette: {
  //primary:
  // light: will be calculated from palette.primary.main,
  //(props: any) =>
  //  props.theme.palette.primary.main
  // dark: will be calculated from palette.primary.main,
  // contrastText: will be calculated to contrast with palette.primary.main

  //},
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "#db3131",
        "&$error": {
          color: "#db3131"
        }
      }
    }
  }
});*/

export const SearchTextInput = (props: CustomTextFieldProps) => {
  const {
    setFunction,
    name,
    name2,
    name3,
    value,
    required,
    validationType,
    propsHelperMessage,
    placeholder,
    maxLength,
    gridSize,
    disabled,
    endInputAdorment,
    width
  } = props;
  const [helperMessage, setHelperMessage] = useState('');
  const [error, setError] = useState(false);
  const [valueLength, setValueLength] = useState(value ? value.toString().length : 0);
  const [inputEvent, setInputEvent] = useState(false);
  const onChange = (e: any) => {
    let data = e.target.value;
    if (validationType) {
      const validationResult = isMatchValidation(validationType || '', data);
      if (!validationResult) {
        setHelperMessage(propsHelperMessage || '');
        setError(true);
      } else {
        setHelperMessage('');
        setError(false);
      }
    }
    setFunction(data);
    setValueLength(data?.length);
  };
  const clearText = () => {
    // 文字列クリアの処理
    setFunction("");
    // 文字クリア後に自身のフィールドへ自動フォーカスさせる
    const field: any = document.querySelector(`input[name=${name}]`);
    if (field) {
      field.focus();
    }
  };
  const onFocus = () => {
    setInputEvent(true);
  };
  const onBlur = () => {
    // フォーカス解除を少し遅らせることで、
    // clearText関数の実行を待つ
    setTimeout((() => {
      setInputEvent(false);
    }), 200);
  };
  useEffect(() => {
    setHelperMessage(helperMessage);
    setValueLength(value ? value.toString().length : 0);
  }, [helperMessage, value, inputEvent]);
  return (
    <SimpleGrid item xs={gridSize}>
      <StyledForm>
        {<Title data-required={required} variant='body1' gutterBottom>
          {name}
        </Title>}
        {name2 && <Title variant='body1' gutterBottom>
          {name2}
        </Title>}
        {name3 && <Title variant='body1' gutterBottom>
          {name3}
        </Title>}
        <StyledOutlinedInput
          name={name}
          onFocus={onFocus}
          onBlur={onBlur}
          error={error}
          sx={{ display: "flex", width: width || smWidth, marginTop: '0px', background: 'white' }}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          required={required}
          disabled={disabled}
          endAdornment={
            <>
              <InputAdornment position="end" disablePointerEvents={false}>
                {valueLength === 0 ?
                  // 空の時は何も表示しない
                  endInputAdorment &&
                  <>
                    {endInputAdorment}
                  </> :
                  error ?
                    // エラーの時
                    <ErrorIcon
                      //className={classes.errorIcon}
                      sx={{ color: '#DC3545' }}
                    /> :
                    // 正常な文字列の時
                    inputEvent ?
                      // 入力中
                      <HighlightOffIcon
                        //className={classes.cancelIcon}
                        sx={{
                          cursor: 'pointer',
                          color: '#DADADA'
                        }}
                        onClick={() => clearText()}
                      />
                      :
                      // 入力中でない時
                      endInputAdorment ?
                        <>
                          {endInputAdorment}
                        </> :
                        <div></div>}
              </InputAdornment>
            </>
          }
          inputProps={{ maxLength: maxLength }}
        />
        <CustomHelperText>{helperMessage}</CustomHelperText>
      </StyledForm>
    </SimpleGrid>
  );
}

export const SingleTextInput = (props: CustomTextFieldProps) => {
  //const classes = makeStyles();
  const {
    setFunction,
    name,
    name2,
    name3,
    value,
    required,
    validationType,
    propsHelperMessage,
    placeholder,
    maxLength,
    gridSize,
    disabled,
    endInputAdorment,
    width
  } = props;
  const [helperMessage, setHelperMessage] = useState('');
  const [error, setError] = useState(false);
  const [valueLength, setValueLength] = useState(value ? value.toString().length : 0);
  const [inputEvent, setInputEvent] = useState(false);
  const onChange = (e: any) => {
    let data = e.target.value;
    if (validationType) {
      const validationResult = isMatchValidation(validationType || '', data);
      if (!validationResult) {
        setHelperMessage(propsHelperMessage || '');
        setError(true);
      } else {
        setHelperMessage('');
        setError(false);
      }
    }
    setFunction(data);
    setValueLength(data?.length);
  };
  const clearText = () => {
    // 文字列クリアの処理
    setFunction("");
    // 文字クリア後に自身のフィールドへ自動フォーカスさせる
    const field: any = document.querySelector(`input[name=${name}]`);
    if (field) {
      field.focus();
    }
  };
  const onFocus = () => {
    setInputEvent(true);
  };
  const onBlur = () => {
    // フォーカス解除を少し遅らせることで、
    // clearText関数の実行を待つ
    setTimeout((() => {
      setInputEvent(false);
    }), 200);
  };
  useEffect(() => {
    setHelperMessage(helperMessage);
    setValueLength(value ? value.toString().length : 0);
  }, [helperMessage, value, inputEvent]);
  return (
    <SimpleGrid item xs={gridSize}>
      <Div>
        <StyledForm>
          {<Title data-required={required} variant='body1' gutterBottom>
            {name}
          </Title>}
          {name2 && <Title variant='body1' gutterBottom>
            {name2}
            {name3 && <Title variant='body1' gutterBottom>
              {name3}
            </Title>}
          </Title>}
          <StyledOutlinedInput
            name={name}
            onFocus={onFocus}
            onBlur={onBlur}
            error={error}
            sx={{ display: "flex", width: width || smWidth, marginTop: '10px' }}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            required={required}
            disabled={disabled}
            endAdornment={
              <>
                <InputAdornment position="end" disablePointerEvents={false}>
                  {valueLength === 0 ?
                    // 空の時は何も表示しない
                    endInputAdorment &&
                    <>
                      {endInputAdorment}
                    </> :
                    error ?
                      // エラーの時
                      <ErrorIcon
                        //className={classes.errorIcon}
                        sx={{ color: '#DC3545' }}
                      /> :
                      // 正常な文字列の時
                      inputEvent ?
                        // 入力中
                        <HighlightOffIcon
                          //className={classes.cancelIcon}
                          sx={{
                            cursor: 'pointer',
                            color: '#DADADA'
                          }}
                          onClick={() => clearText()}
                        />
                        :
                        // 入力中でない時
                        endInputAdorment ?
                          <>
                            {endInputAdorment}
                          </> :
                          <div></div>}
                </InputAdornment>
              </>
            }
            inputProps={{ maxLength: maxLength }}
          />
          <CustomHelperText>{helperMessage}</CustomHelperText>
        </StyledForm>
      </Div>
    </SimpleGrid>
  );
}

export const MultiLineTextInput = (props: CustomTextFieldProps) => {
  //const classes = useStyles();
  const rows = props.rows ? props.rows : 5;
  const {
    setFunction,
    name,
    value,
    required,
    validationType,
    propsHelperMessage,
    readOnly,
    placeholder,
    helperText1,
    helperText2,
    maxLength,
  } = props;
  const [helperMessage, setHelperMessage] = useState('');
  const [error, setError] = useState(false);
  const [valueLength, setValueLength] = useState(value ? value.toString().length : 0);
  const [inputEvent, setInputEvent] = useState(false);
  const onChange = (e: any) => {
    let data = e.target.value;
    if (validationType) {
      const validationResult = isMatchValidation(validationType || '', data);
      if (validationResult) {
        setHelperMessage(propsHelperMessage || '');
        setError(true);
      } else {
        setHelperMessage('');
        setError(false);
      }
    }
    setFunction(data);
    setValueLength(data?.length);
  };
  const clearText = () => {
    // 文字列クリアの処理
    setFunction("");
    // 文字クリア後に自身のフィールドへ自動フォーカスさせる
    const field: any = document.querySelector(`input[name=${name}]`);
    if (field) {
      field.focus();
    }
  };
  const onFocus = () => {
    setInputEvent(true);
  };
  const onBlur = () => {
    // フォーカス解除を少し遅らせることで、
    // clearText関数の実行を待つ
    setTimeout((() => {
      setInputEvent(false);
    }), 200);
  };
  useEffect(() => {
    setHelperMessage(helperMessage);
    setValueLength(value ? value.toString().length : 0);
  }, [helperMessage, value, inputEvent]);
  return (
    <SimpleGrid item xs={props.gridSize}>
      <Div>
        <StyledForm>
          {<Title data-required={required} variant='body1' gutterBottom>
            {name}
          </Title>}
          <StyledTextField
            name={name}
            onFocus={onFocus}
            onBlur={onBlur}
            error={error}
            //classes={{ root: classes.root }}
            placeholder={placeholder}
            onChange={onChange}
            label={name}
            multiline
            rows={rows}
            variant="outlined"
            value={value}
            required={required}
            helperText={helperMessage}
            FormHelperTextProps={{
              //className: classes.helperText,
            }}
            InputLabelProps={{
              shrink: true,
              style: {
                color: error ? '#db3131' : "#000",
              },
            }}
            InputProps={{
              readOnly: readOnly,
              endAdornment:
                <InputAdornment position="end" disablePointerEvents={false}>
                  {valueLength === 0 ?
                    // 空の時は何も表示しない
                    <></> :
                    error ?
                      // エラーの時
                      <StyledErrorIcon
                      //className={classes.errorIcon}
                      /> :
                      // 正常な文字列の時
                      inputEvent ?
                        // 入力中
                        <StyledHighlightOffIcon
                          //className={classes.cancelIcon}
                          onClick={() => clearText()}
                        />
                        :
                        // 入力中でない時
                        <StyledDoneIcon
                        //className={classes.doneIcon}
                        />}
                </InputAdornment>,
            }}
            inputProps={{ maxLength: maxLength }}
          />
          <HelperWrap>
            {helperText1 ? <StyledFormHelper>{helperText1}</StyledFormHelper> : null}
            {helperText2 ? <StyledFormHelper>{helperText2}</StyledFormHelper> : null}
          </HelperWrap>
        </StyledForm>
      </Div>
    </SimpleGrid>
  );
}

const StyledOutlinedInput = styled(OutlinedInput)`
  .MuiInputBase-input {
    padding: 10px 20px;
  }
`

const StyledTextField = styled(MuiTextField)`
.MuiInputBase-root {
  padding: 10px 20px;
}

.MuiInputBase-input {
  padding: 0;
}
input:-webkit-autofill {
  /* inputがオートコンプリート（自動選択）されたときのフォーム内の色を指定する。デフォでは水色 */
  /* 色はinput要素の背景色（ここでは#fafafa）に一旦揃える */
  box-shadow: 0 0 0px 999px #fafafa inset;
}`;

const StyledDoneIcon = styled(DoneIcon)`
position: relative;
top: ${smTop};
@media screen and (min-width: ${(props: any) =>
    props.theme.breakpoints.values.sm}px){
  position: relative;
  top: ${smTop};
}`;

const StyledErrorIcon = styled(ErrorIcon)`
position: relative;
top: ${smTop};
@media screen and (min-width: ${(props: any) =>
    props.theme.breakpoints.values.sm}px){
  position: relative;
  top: ${smTop};
}`;

const StyledHighlightOffIcon = styled(HighlightOffIcon)`
position: relative;
top: 45px;
@media screen and (min-width: ${(props: any) =>
    props.theme.breakpoints.values.sm}px){
  position: relative;
  top: ${smTop};
}`;

const StyledForm = styled('div')`
margin:${(props: any) =>
    props.theme.spacing(2)}px;
  width: calc(100% - ${(props: any) =>
    props.theme.spacing(0)}px * 2);
@media screen and (min-width: ${(props: any) =>
    props.theme.breakpoints.values.sm}px){
    width: auto;
}
`

const Div = styled('div')`
    display:flex;
    flex-wrap: wrap;
    width: calc(100% - ${(props: any) =>
    props.theme.spacing(0)}px * 2);
    margin-top: 25px; 
`

const HelperWrap = styled('div')`
display: flex;
flex-wrap: wrap;
@media screen and (min-width: ${(props: any) =>
    props.theme.breakpoints.values.sm}px) {
}
`;

const StyledFormHelper = styled(FormHelperText)`
  color: red;
  margin-left: ${(props: any) =>
    props.theme.spacing(2)}px;
`;

export const CircledAvatar = styled(Avatar)`
width: 24px;
height: 24px;
margin:${(props: any) =>
    props.theme.spacing(2)}px ${(props: any) =>
      props.theme.spacing(1)}px  ${(props: any) =>
        props.theme.spacing(1)}px ${(props: any) =>
          props.theme.spacing(1)}px;
@media screen and (min-width: ${(props: any) =>
    props.theme.breakpoints.values.sm}px){
  width: 32px;
  height: 32px
}
`;

export const Title = styled(Typography)`
    margin: 10px  ${props => props.theme.spacing(2)}px ${props => props.theme.spacing(1)}px 0;
    margin-top: 0px;
    width: calc(240px - ${props => props.theme.spacing(2)}px * 3);
    &[data-required="true"] {
        &::after{
          content:'*';
          margin-left: 5px;
          font-size: 20px;
          color:red;
        }
    }
    //font-family: Noto Sans JP;
    font-family: YuGothic;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #353535;
`;

export const CustomIconButton = styled(IconButton)`
  width: 24px;
  height: 24px;
`;

export const CustomHelperText = styled(FormHelperText)`
  color: red;
`;