import { AnalyzationResult } from '@sleepwell_new_platform/common-types/src/analyzationResults';
import dayjs, { Dayjs } from 'dayjs';
import React, { useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine, ResponsiveContainer, XAxisProps } from 'recharts';
import { getNearestHalfMinute } from '../../../utils/analyzations';
import { styled } from '@mui/system';

type CustomDotProps = {
    cx: number;
    cy: number;
    payload: {
        category: string;
        value: number;
    };
}
interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
  label?: string;
}
const CustomDot= (props: CustomDotProps) => {
    let fill;
    switch (props.payload.category) {
        case 'W':
            fill = '#727170';
            break;  
        case 'M':
            fill = '#696969';
              break;
        case 'R':
              fill = '#F1715F';
              break;  
        case '1':
            fill = '#7ABAC6';
            break;
        case '2':
            fill = '#5FA5D7';
            break;
        case '3':
          fill = '#4F83C1';
          break;
        default:
            fill = 'gray';
    }
    return <circle cx={props.cx} cy={props.cy} r={4} fill={fill} />;
};
const categoryTickFormatter = (value: number, lang: 'ja' | 'en') => {
  if(lang === 'ja') {
    switch (value) {
      case 5:
          return '覚醒（W)';
      case 4:
          return 'レム睡眠（R）';
      case 3:
          return 'ノンレム睡眠（N1）';
      case 2:
          return 'ノンレム睡眠（N2）';
      case 1:
          return 'ノンレム睡眠（N3）';
      default:
          return '';
    }
  } else {
    switch (value) {
      case 5:
          return 'Wake';
      case 4:
          return 'REMSleep';
      case 3:
          return 'NonREM1 (N1)';
      case 2:
          return 'NonREM2 (N2)';
      case 1:
          return 'NonREM3 (N3)';
      default:
          return '';
  }
  }
};

const categoryToValue = (category: string) => {
  switch (category) {
    case 'W':
      return 5;
    case 'M':
      return 5;
    case 'R':
      return 4;
    case '1':
      return 3;
    case '2':
      return 2;
    case '3':
      return 1;
    default:
      return 0;
}
}
const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{width: 150, height: 100, background: 'white', border: '1px solid black'}}>
        <p>{`時刻: ${label}`}</p>
        <p>{`カテゴリ: ${payload[0].payload.category}`}</p>
      </div>
    );
  }

  return null;
};


const hourTickFormatter = (time: string, startTime: string, index: number, lastIndex: number) => {
  const timeParts = time.split(':');
  const hour = timeParts[0];
  const minute = timeParts[1];
  if(index === 0) {
    return `${hour}:${minute}`;
  }
  if(index === 1) {
    return `${hour}:${minute}`;
  }
  return '';
};

type Props = {
  analyzationResultData: AnalyzationResult | null;
  openReportModal:boolean;
  measureStartDate: number;
  height?: number | string;
  lang: 'ja' | 'en';
  isScale: boolean;
}

const SleepLineChart = (props: Props) => {
  const { analyzationResultData, openReportModal, measureStartDate, height, lang, isScale } = props;
  const chartData = useMemo(() => {
   //dayjs(measureStartDate).format('HH:mm:ss');
    const nearestHour = getNearestHalfMinute(dayjs(measureStartDate).format('YYYY/MM/DD HH:mm:ss'));
    const formattedNearestHour = nearestHour.format('HH:mm:ss');
    const startTime = formattedNearestHour;
    const deltaPowerList = analyzationResultData?.deltaPowerList;
    const sleepStageList = analyzationResultData?.sleepStageList;
    // startTimeを基準に30秒ずつ追加していく24時を超えたら0時に戻る
    // Array<{time: string, nonreme: number}> noremにdeletaPowerを入れる
    const chartData: {
      time: string;
      category: string;
      value: number;
    }[] = [];
    let time = startTime;
    if (!deltaPowerList || !sleepStageList) return [];
    deltaPowerList.forEach((deltaPower, index) => {
      const sleepStage = sleepStageList[index];
      chartData.push({
        time,
        category: sleepStage,
        value: categoryToValue(sleepStage),
      });
      const [hours, minutes, seconds] = time.split(':').map(Number);
      const newSeconds = seconds + 30;
      const carryMinutes = Math.floor(newSeconds / 60);
      const adjustedSeconds = newSeconds % 60;
      const newMinutes = minutes + carryMinutes;
      const carryHours = Math.floor(newMinutes / 60);
      const adjustedMinutes = newMinutes % 60;
      const newHours = hours + carryHours;
      const adjustedHours = newHours % 24;
      time = `${String(adjustedHours).padStart(2, '0')}:${String(adjustedMinutes).padStart(2, '0')}:${String(adjustedSeconds).padStart(2, '0')}`;
    });
    return chartData;
  }, [analyzationResultData, openReportModal, measureStartDate]);

  // 1時間ごとにreferenceLineを追加する
  // 3の倍数の時間の時は赤色にする
  // 0時の時は赤色にする
  const referenceLines = useMemo(() => {
    if(chartData.length === 0) return [];
    const startYYYYMMDD = dayjs(measureStartDate).format('YYYY/MM/DD');
    const startTime = dayjs(`${startYYYYMMDD} ${chartData[0]?.time}`);
    const startHour = Number(chartData[0]?.time.split(':')[0]);
    // endTimeが0時を超えている場合1日足す　そうではない場合はそのまま
    const endHour = Number(chartData[chartData.length - 1].time.split(':')[0]);
    // endTimeが0時を超えている場合1日足す　そうではない場合はそのまま
    const endYYYYMMDD = (startHour <= endHour && endHour < 24) ? startYYYYMMDD : dayjs(startYYYYMMDD).add(1, 'day').format('YYYY/MM/DD');
    const endTime = dayjs(`${endYYYYMMDD} ${chartData[chartData.length - 1].time}`);
    const referenceLines: JSX.Element[] = [];
    const isWithinTimeRange = (time: Dayjs): boolean => {
      const diffToStart = time.diff(startTime, 'minute');
      const diffToEnd = endTime.diff(time, 'minute');
      return diffToStart >= 20 && diffToEnd >= 20;
    };
    for (let i = 0; i < 24; i++) {
      const mm = dayjs(measureStartDate).format('mm');
      const timeStr = `${String(i).padStart(2, '0')}:${mm}:00`;
      const timeHour = Number(timeStr.split(':')[0]);
      const currentTimeYYYYMMDD = (startHour <= timeHour && timeHour < 24) ? startYYYYMMDD : dayjs(startYYYYMMDD).add(1, 'day').format('YYYY/MM/DD');
      const time = dayjs(`${currentTimeYYYYMMDD} ${timeStr}`);
      // startTimeやendTimeとの差が20分未満の場合はスキップ
      const stroke = 'gray';
      const label = `${String(i).padStart(2, '0')}:${mm}`;
      // startTimeやendTimeとの差が20分未満の場合はスキップ
      if (!isWithinTimeRange(time)) continue;
      referenceLines.push(
        <ReferenceLine x={timeStr} stroke={stroke} label={{ value: label, position: 'bottom', fill: stroke, fontSize: 14, dy: 3 }} />
      );
      if(i % 3 === 0) {
        const timeStrRed = `${String(i).padStart(2, '0')}:00:00`;
        const labelRed = `${String(i).padStart(2, '0')}:00`;
        referenceLines.push(
          <ReferenceLine x={timeStrRed} stroke={'red'} label={{ value: labelRed, position: 'top', fill: 'red', fontSize: 14, dy: -3 }} />
        );
      }
    }
    return referenceLines;
  }, [chartData]);
  const xAxisProps: XAxisProps = {
    height: 20,
    dataKey: 'time',
  };
  

  if(!analyzationResultData) return null;
  return(
    <Container isScale={isScale}>
      <StyledResponsiveContainer width={'100%'} height={height ? Number(height): 500}>
        <LineChart 
          key={openReportModal ? 1 : 0}
          data={chartData}
          margin={{ top: 30, right: 20, bottom: 30, left: 100 }}
          >
            <CartesianGrid strokeDasharray="1 1"/>
            <XAxis  {...xAxisProps}  tickFormatter={(time, index) => hourTickFormatter(time, dayjs(measureStartDate).format('HH:mm:ss'), index, chartData.length)} interval={chartData.length-2}  tick={{ fontSize: 14 }}/>
            <YAxis domain={[0, 6]}
                ticks={[1, 2, 3, 4, 5]} tickFormatter={(value) =>categoryTickFormatter(value, lang)} tick={{ fontSize: 14 }}/>
            <Tooltip/>
            <Line type="linear" dataKey="value"  stroke="#353535" strokeWidth={1} dot={CustomDot} />
            {referenceLines}
        </LineChart>
      </StyledResponsiveContainer>
    </Container>
  )
};

const StyledResponsiveContainer = styled(ResponsiveContainer)`
`

type ContainerProps = {
  isScale: boolean;
}

const Container = styled('div')<ContainerProps>`
  @media print {
    transform: ${props => props.isScale ? 'translateX(-5%) scale(0.9)' : 'none'};  
  }
`

export default SleepLineChart;
