import { Box, Typography, styled } from '@mui/material';
import React from 'react';
import { Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer, Dot } from 'recharts';
import { BodyText } from '../../atoms/Text';

const HexagonalBackground = ({ width, height } : {width :number, height: number}) => {
  // 背景の六角形を描画する関数
  const drawHexagon = (cx:number, cy:number, radius:number) => {
    const sides = 6; // 六角形の辺の数
    const points = [];
    // 六角形の各頂点の座標を計算
    for (let i = 0; i < sides; i++) {
      const angle = (Math.PI / 3) * i;
      const x = cx + radius * Math.cos(angle);
      const y = cy + radius * Math.sin(angle);
      points.push(`${x},${y}`);
    }
    return <polygon points={points.join(" ")} fill="#ffffff" />;
  };
  const centerX = width / 2;
  const centerY = height / 2;
  const radius = Math.min(width, height) / 2 - 50; // 余白を設ける
  return (
    <svg width={width} height={height} style={{ position: 'absolute', overflow: "hidden", transform: 'rotate(90deg) translateY(-54px) translateX(0px)'}}>
      {drawHexagon(centerX, centerY, radius)}
    </svg>
  );
};
const PentagonBackground = ({ width, height }: { width: number; height: number }) => {
  // 背景の五角形を描画する関数
  const drawPentagon = (cx: number, cy: number, radius: number) => {
    const sides = 5; // 五角形の辺の数
    const points = [];
    // 五角形の各頂点の座標を計算
    for (let i = 0; i < sides; i++) {
      const angle = (Math.PI * 2 / sides) * i; // Calculate the angle for each vertex
      const x = cx + radius * Math.cos(angle);
      const y = cy + radius * Math.sin(angle);
      points.push(`${x},${y}`);
    }
    return <polygon points={points.join(" ")} fill="#ffffff" />;
  };
  const centerX = width / 2;
  const centerY = height / 2;
  const radius = Math.min(width, height) / 2 - 50; // 余白を設ける
  return (
    <svg width={width} height={height} style={{ position: "absolute", transform: "rotate(54deg) translateX(-26px) translateY(36px)" }}>
      {drawPentagon(centerX, centerY, radius)}
    </svg>
  );
};
type Props = {
  sleepEvaluation: Array<{subject: string, you: number, average: number}>;
  matureSleepBrainwaveAge: number;
  lang: 'ja' | 'en';
  age: number;
}

const SleepRadarChart = (props: Props) => {
  const { sleepEvaluation,  matureSleepBrainwaveAge, lang, age} = props;
  const CustomLegend = (props: any) => {
    const { payload } = props;
    return (
      <ul style={{ listStyleType: 'none', margin: 0, padding: 0, fontSize: '12px', fontFamily: 'ABeeZee', color: '#353535', fontWeight: 'bold' }}>
        {
          payload.map((entry: any, index: number) => (
            <li key={`item-${index}`} style={{ marginBottom: '1px' }}>
              <span style={{ color: entry.color }}>{entry.value}</span>
            </li>
          ))
        }
      </ul>
    );
  };
  return (
    <div style={{display: "flex", marginTop: 16, justifyContent: "space-between", alignItems: "flex-start"}}>
    <Wrap>
      <div style={{marginTop: 8, marginLeft: 20}}>
      <BodyText style={{fontWeight: 700, fontSize: 16}}>{lang === 'ja' ? "睡眠評価結果" : "Sleep Assessment Results"}</BodyText>
      </div>
      <div style={{ position: 'relative', width: '100%', height: '300px',  }}>
      { age >= 19? <HexagonalBackground width={300} height={300} /> : <PentagonBackground width={500} height={300} />}
      <ResponsiveContainer width="100%" height="100%">
        <RadarChart data={sleepEvaluation}>
          <PolarGrid />
          <PolarAngleAxis dataKey="subject" tick={{ fill: '#353535', fontSize: 11, fontFamily: 'ABeeZee',  width: 12 }} />
          <PolarRadiusAxis 
            angle={age >= 19 ? 30 : 45} 
            domain={[0, 5]}  
            tickCount={6}         
            />
          <Radar name={lang === 'ja' ? "あなたの評価(5段階)" : 'Your assessment(5 stages)'} dataKey="you" stroke="#EC404D" fillOpacity={0} strokeWidth={3} >
            <Dot r={4} fill="#EC404D" />
          </Radar>
          <Radar name={lang === 'ja' ?"同年代平均値" : "Average of generation"} dataKey="average" stroke="#529BC5" fillOpacity={0} strokeWidth={3}/>
          <Legend content={<CustomLegend />}/>
        </RadarChart>
      </ResponsiveContainer>
    </div>
    </Wrap>
    {age >= 19  &&
    <Box
      display="flex"
      flexDirection="column"
    >
      <BlackBox>
      {props.lang === 'ja' ? <AgeText variant="body1">{`熟眠脳波年齢は`}<AgeSpan>{matureSleepBrainwaveAge.toFixed(0)}</AgeSpan>歳です</AgeText> : 
       <AgeText variant="body1" style={{lineHeight: '18px'}}>{`Deep Sleep Brainwave Age`}<AgeSpan>{matureSleepBrainwaveAge.toFixed(0)}</AgeSpan> years old</AgeText>}
      </BlackBox>
      <DescriptionBox>
        {lang === 'ja' ?
        <StyledTypography variant="body1" style={{color: "#4B4B4B", fontSize: "13px"}}>{"熟眠脳波年齢は、第１周期（入眠〜最初のレム睡眠直前まで）に出現するデルタ波（深い睡眠時に多く出現し、熟睡度や眠りの深さの指標）の量を年齢別に平均値を算出し、脳波年齢を推定しています。熟眠脳波年齢が、実際の年齢より高い場合でも、総合判定が「A（良好）」であれば、心配はいりません。熟眠脳波年齢が、実際の年齢より低い場合でも、総合判定が「D（要精密検査）」の場合、専門医への受診をお勧めします。"}</StyledTypography> : 
        <StyledTypography variant="body1" style={{color: "#4B4B4B"}}>{'Deep Sleep Brainwave Ageis calculated agewise by the average volume of delta waves (usually appear during deep sleep, indicates soundness and depth of sleep) that appear during the 1st cycle (sleep onset ~ before 1st REM sleep), and estimates brainwave age. If Deep Sleep Brainwave Age is higher than your actual age, but the inspection result is "A (Good)", there is of no concern. If Deep Sleep Brainwave Age is lower than your actual age, but the inspection result is "D (Need detailed examination)", consulting a specialist is recommended.'}</StyledTypography>}
      </DescriptionBox>
    </Box>}
    </div>
  );
};

export default SleepRadarChart;

const Wrap = styled('div')({
  borderRadius: '1px',
  border: '1px solid #E5E5E5',
  background: '#F2F2F2',
  width: '410px',
  flexShrink: 0,
  marginRight: 8
  });
  
const BlackBox = styled('div')({
  display: "flex",
  padding: "10px",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "10px",
  borderRadius: "1px",
  border: "1px solid #E5E5E5",
  background: "#4B4B4B",
});

const DescriptionBox = styled('div')({
  border: "1px solid #E5E5E5",
  background: "#F9FDFF",
  padding: "10px",
  borderRadius: "2px",
  backgroundColor: "#F9FDFF",
  '@media print': {
  }
});

const StyledTypography = styled(Typography)<{lang?: "ja" | "en"}>`
  font-size: ${({lang})=>lang === "ja" ? "14px": "10.5px"};
  @media print {
    font-size: ${({lang})=>lang === "ja" ? "12px": "10.5px"};
  }
`

// const StyledTypography = styled(Typography)(()=>({
//   fontSize: "15px",
//   '@media print': {
//     fontSize: "12px"
//   }
// }))

const StyledRadarChart = styled(RadarChart)({
  '.recharts-surface': {
    marginTop: '-20px'
  }
})
const AgeText = styled(Typography)(()=>({
  fontSize: "14px",
  color: "#fff",
  fontWeight: 700,
  '@media print': {
    fontSize: "12px"
  }
}));

const AgeSpan = styled('span')({ 
  fontSize: "20px",
  margin: '0 4px',
  color: "#fff",
  fontWeight: 700,
  '@media print': {
    fontSize: "18px",
    margin: '0 ',
  }
})