export const prefectureList = [
  {value: "北海道", lavel: "北海道", area: '北海道地方' },
  {value: "青森県", lavel: "青森県" , area:'東北地方'},
  {value: "岩手県", lavel: "岩手県" , area:'東北地方'},
  {value: "宮城県", lavel: "宮城県" , area:'東北地方'},
  {value: "秋田県", lavel: "秋田県" , area:'東北地方'},
  {value: "山形県", lavel: "山形県" , area:'東北地方'},
  {value: "福島県", lavel: "福島県" , area:'東北地方'},
  {value: "茨城県", lavel: "茨城県" , area: '関東地方'},
  {value: "栃木県", lavel: "栃木県" , area: '関東地方'},
  {value: "群馬県", lavel: "群馬県" , area: '関東地方'},
  {value: "埼玉県", lavel: "埼玉県" , area: '関東地方'},
  {value: "千葉県", lavel: "千葉県" , area: '関東地方'},
  {value: "東京都", lavel: "東京都" , area: '関東地方' },
  {value: "神奈川県", lavel: "神奈川県" , area: '関東地方' },
  {value: "新潟県", lavel: "新潟県" , area: '中部地方'},
  {value: "富山県", lavel: "富山県" , area: '中部地方'},
  {value: "石川県", lavel: "石川県" , area: '中部地方'},
  {value: "福井県", lavel: "福井県" , area: '中部地方'},
  {value: "山梨県", lavel: "山梨県" , area: '中部地方'},
  {value: "長野県", lavel: "長野県" , area: '中部地方'},
  {value: "岐阜県", lavel: "岐阜県" , area: '中部地方'},
  {value: "静岡県", lavel: "静岡県" , area: '中部地方'},
  {value: "愛知県", lavel: "愛知県" , area: '中部地方'},
  {value: "三重県", lavel: "三重県" , area: '近畿地方'},
  {value: "滋賀県", lavel: "滋賀県" , area: '近畿地方'},
  {value: "京都府", lavel: "京都府" , area: '近畿地方'},
  {value: "大阪府", lavel: "大阪府" , area: '近畿地方'},
  {value: "兵庫県", lavel: "兵庫県" , area: '近畿地方'},
  {value: "奈良県", lavel: "奈良県" , area: '近畿地方'},
  {value: "和歌山県", lavel: "和歌山県" , area: '近畿地方'},
  {value: "鳥取県", lavel: "鳥取県" , area: '中国地方'},
  {value: "島根県", lavel: "島根県" , area: '中国地方'},
  {value: "岡山県", lavel: "岡山県" , area: '中国地方'},
  {value: "広島県", lavel: "広島県" , area: '中国地方'},
  {value: "山口県", lavel: "山口県" , area: '中国地方'},
  {value: "徳島県", lavel: "徳島県" , area: '四国地方'},
  {value: "香川県", lavel: "香川県" , area: '四国地方'},
  {value: "愛媛県", lavel: "愛媛県" , area: '四国地方'},
  {value: "高知県", lavel: "高知県" , area: '四国地方'},
  {value: "福岡県", lavel: "福岡県" , area: '九州地方'},
  {value: "佐賀県", lavel: "佐賀県" , area: '九州地方'},
  {value: "長崎県", lavel: "長崎県" , area: '九州地方'},
  {value: "熊本県", lavel: "熊本県" , area: '九州地方'},
  {value: "大分県", lavel: "大分県" , area: '九州地方'},
  {value: "宮崎県", lavel: "宮崎県" , area: '九州地方'},
  {value: "鹿児島県", lavel: "鹿児島県" , area: '九州地方'},
  {value: "沖縄県", lavel: "沖縄県" , area: '沖縄地方'},
]

export const areaList = [
  {value: "北海道地方", lavel: "北海道地方" },
  {value: "東北地方", lavel: "東北地方" },
  {value: "関東地方", lavel: "関東地方" },
  {value: "中部地方", lavel: "中部地方" },
  {value: "近畿地方", lavel: "近畿地方" },
  {value: "中国地方", lavel: "中国地方" },
  {value: "四国地方", lavel: "四国地方" },
  {value: "九州地方", lavel: "九州地方" },
  {value: "沖縄地方", lavel: "沖縄地方" },
]

export const prefectureMapList = [
  {value: "北海道", label: "北海道", enLabel: "Hokkaido"},
  {value: "青森県", label: "青森県", enLabel: "Aomori"},
  {value: "岩手県", label: "岩手県", enLabel: "Iwate"},
  {value: "宮城県", label: "宮城県", enLabel: "Miyagi"},
  {value: "秋田県", label: "秋田県", enLabel: "Akita"},
  {value: "山形県", label: "山形県", enLabel: "Yamagata"},
  {value: "福島県", label: "福島県", enLabel: "Fukushima"},
  {value: "茨城県", label: "茨城県", enLabel: "Ibaraki"},
  {value: "栃木県", label: "栃木県", enLabel: "Tochigi"},
  {value: "群馬県", label: "群馬県", enLabel: "Gunma"},
  {value: "埼玉県", label: "埼玉県", enLabel: "Saitama"},
  {value: "千葉県", label: "千葉県", enLabel: "Chiba"},
  {value: "東京都", label: "東京都", enLabel: "Tokyo"},
  {value: "神奈川県", label: "神奈川県", enLabel: "Kanagawa"},
  {value: "新潟県", label: "新潟県", enLabel: "Niigata"},
  {value: "富山県", label: "富山県", enLabel: "Toyama"},
  {value: "石川県", label: "石川県", enLabel: "Ishikawa"},
  {value: "福井県", label: "福井県", enLabel: "Fukui"},
  {value: "山梨県", label: "山梨県", enLabel: "Yamanashi"},
  {value: "長野県", label: "長野県", enLabel: "Nagano"},
  {value: "岐阜県", label: "岐阜県", enLabel: "Gifu"},
  {value: "静岡県", label: "静岡県", enLabel: "Shizuoka"},
  {value: "愛知県", label: "愛知県", enLabel: "Aichi"},
  {value: "三重県", label: "三重県", enLabel: "Mie"},
  {value: "滋賀県", label: "滋賀県", enLabel: "Shiga"},
  {value: "京都府", label: "京都府", enLabel: "Kyoto"},
  {value: "大阪府", label: "大阪府", enLabel: "Osaka"},
  {value: "兵庫県", label: "兵庫県", enLabel: "Hyogo"},
  {value: "奈良県", label: "奈良県", enLabel: "Nara"},
  {value: "和歌山県", label: "和歌山県", enLabel: "Wakayama"},
  {value: "鳥取県", label: "鳥取県", enLabel: "Tottori"},
  {value: "島根県", label: "島根県", enLabel: "Shimane"},
  {value: "岡山県", label: "岡山県", enLabel: "Okayama"},
  {value: "広島県", label: "広島県", enLabel: "Hiroshima"},
  {value: "山口県", label: "山口県", enLabel: "Yamaguchi"},
  {value: "徳島県", label: "徳島県", enLabel: "Tokushima"},
  {value: "香川県", label: "香川県", enLabel: "Kagawa"},
  {value: "愛媛県", label: "愛媛県", enLabel: "Ehime"},
  {value: "高知県", label: "高知県", enLabel: "Kochi"},
  {value: "福岡県", label: "福岡県", enLabel: "Fukuoka"},
  {value: "佐賀県", label: "佐賀県", enLabel: "Saga"},
  {value: "長崎県", label: "長崎県", enLabel: "Nagasaki"},
  {value: "熊本県", label: "熊本県", enLabel: "Kumamoto"},
  {value: "大分県", label: "大分県", enLabel: "Oita"},
  {value: "宮崎県", label: "宮崎県", enLabel: "Miyazaki"},
  {value: "鹿児島県", label: "鹿児島県", enLabel: "Kagoshima"},
  {value: "沖縄県", label: "沖縄県", enLabel: "Okinawa"}
];
