import { Box, styled } from "@mui/material";
import { DataGrid as MuiDataGrid, GridColDef, GridRenderCellParams, GridValueFormatterParams, GridValueGetterParams, GridSortModel, GridSortItem } from "@mui/x-data-grid";
import { Affiliation, Analyzation, Clinics, Patients } from "@sleepwell_new_platform/common-types";
import { IsReadLabel } from "../../../icons";
import MuiLink from '@mui/material/Link';
import { ArrowIcon } from "../../../icons/analyzation";
import dayjs from "../../../lib/dayjs";
import { getAnalyzationStatusLabel, isAnalyzationProcessDone } from "../../../utils/analyzations";
import { useMemo, useState } from "react";
import { DataGridPro, GRID_CHECKBOX_SELECTION_COL_DEF, GridValidRowModel } from '@mui/x-data-grid-pro';
import { AnalyzationStatus } from "../../../utils/analyzations/analyzations";

type Props = {
  analyzations: Analyzation[];
  handleDownloadEDF: (data: Analyzation) => void;
  patients: Patients[];
  affiliations: Affiliation[];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setAnalyzationState: React.Dispatch<React.SetStateAction<Analyzation | undefined>>;
  setSelectList: React.Dispatch<React.SetStateAction<string[]>>;
  setOpenReportModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpenRejectModal: React.Dispatch<React.SetStateAction<boolean>>;
  clinic: Clinics | null;
  setOpenDocA3ReportModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenDocA4ReportModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleDownloadSingleFFT: (data: Analyzation) => void;
  sortModel: GridSortModel;
  setSortModel: React.Dispatch<React.SetStateAction<GridSortModel>>;
}

export const DataGrid = (props: Props) => {
  const { analyzations, handleDownloadEDF, patients, affiliations, setOpen,  setAnalyzationState, setSelectList, setOpenReportModal, setIsOpenRejectModal, clinic, setOpenDocA3ReportModal, setOpenDocA4ReportModal, handleDownloadSingleFFT, sortModel, setSortModel} = props;
  const [pinnedState, setPinnedState] = useState<{left: string[], right: string[]}>({
    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'isRead', 'operation', 'report', 'fftFileName', 'edfFileName', 'status'],
    right: []
  });
  const columns: any = useMemo(() => {
    const isInputPatientName = clinic?.isInputPatientName;
    let personalAnalyzationColumns: GridColDef<GridValidRowModel>[]  = [
      {
        field: 'operation',
        headerName: '操作',
        width: 100,
        renderCell: (params: GridRenderCellParams<Analyzation, any, any>) => {
          const isProcessDone = ['confirmed', 'failed'].includes(params.row.status);
          // モーダルが開く
          return (
            <Wrapper>
            {isProcessDone && 
            <FlexWrap>
              <StyledMuiLink 
                  onClick={()=> {
                    setAnalyzationState(params.row);
                    setOpen(true)}}
                  style={{marginRight:8}}>
                  解析情報
                </StyledMuiLink>
              <ArrowIcon />
            </FlexWrap>}
            </Wrapper>
          )
        }
      },
      {
        field: 'report',
        headerName: 'レポート',
        width: 150,
        renderCell: (params: GridRenderCellParams<Analyzation, any, any>) => {
          const isProcessDone = ['confirmed'].includes(params.row.status);
          // レポートページを表示する
          return (
            <Wrapper>
            {isProcessDone &&
            <FlexWrap>
                <StyledMuiLink  onClick={()=> {
                        setAnalyzationState(params.row);
                        if(clinic?.reportType === 'doctorA3'){
                          setOpenDocA3ReportModal(true);
                        }
                        if(clinic?.reportType === 'doctorA4'){
                          setOpenDocA4ReportModal(true);
                        }
                        if(clinic?.reportType === 'research'){
                          setOpenReportModal(true);
                        }
                }}   style={{marginRight:8}}>
                  レポート表示
                </StyledMuiLink>
              <ArrowIcon/>
            </FlexWrap>}
            </Wrapper>
          )
        }
      },
      {
        field: 'fftFileName',
        headerName: 'FFT解析結果',
        width: 120,
        renderCell: (params: GridRenderCellParams<Analyzation, any, any>) => {
          //ここで署名付きダウンロードを行う
          const isProcessDone = params.row.status === 'confirmed';
          return (
            <>
              {isProcessDone && <FlexWrap>
                <StyledMuiLink onClick={() => handleDownloadSingleFFT(params.row)}>
                  {'ダウンロード'}
                </StyledMuiLink>
              </FlexWrap>}
            </>
          )
        }
      },
      {
        field: 'edfFileName',
        headerName: 'EDFファイル名',
        width: 200,
        renderCell: (params: GridRenderCellParams<Analyzation, any, any>) => {
          //ここで署名付きダウンロードを行う
          return (
            <FlexWrap>
                <StyledMuiLink  onClick={()=> handleDownloadEDF(params.row)}>
                  {params.row.edfFileName}
                </StyledMuiLink>
            </FlexWrap>
          )
        }
      },
      {
        field: 'status',
        headerName: '解析状態',
        width: 150,
        renderCell: (params: GridRenderCellParams<Analyzation, any, any>) => {
          if (isAnalyzationProcessDone(params.row.status)) {
            if(['failed', 'confirmed'].includes(params.row.status)) {
              return getAnalyzationStatusLabel(params.row.status);
            } else {
              return <LinkWrap onClick={() => {
                setAnalyzationState(params.row);
                setIsOpenRejectModal(true);
              }}
              isProcessDone={!isAnalyzationProcessDone(params.row)} 
              >{getAnalyzationStatusLabel(params.row.status)}</LinkWrap>
            }
          } else {
            return <LinkWrap onClick={() => {
              setAnalyzationState(params.row);
              //setIsOpenRejectModal(true);
            }}
            isProcessDone={isAnalyzationProcessDone(params.row)} 
            >{getAnalyzationStatusLabel(params.row.status)}</LinkWrap>
          }
        }
      },
      {
        field: 'rejectMesssage',
        headerName: '解析メッセージ',
        width: 350,
        renderCell: (params: GridRenderCellParams<Analyzation, any, any>) => {
          const analyzation = params.row as Analyzation
          const rejectMessageList = analyzation.rejectMessageList;
          if(!rejectMessageList){
            return <></>
          }
          const isProcessDone = isAnalyzationProcessDone(analyzation?.status as AnalyzationStatus);
          let message = Array.from(new Set(rejectMessageList.filter((doc) => isProcessDone && doc.userMessage !== "スリープウェルにて解析結果を検証中です。しばらくお待ちください。").map((doc) => doc.userMessage))).join("\n");
          if(analyzation?.status === AnalyzationStatus.WAITING_FOR_CONFIRM){
            message = "スリープウェルにて解析結果を検証中です。しばらくお待ちください。"
          }
          if(analyzation?.status === "confirmed"){
            message = ''
          }
          return   <StylyedRejectMessage>{message}</StylyedRejectMessage>
        }
      },
      {
        field: 'equipmentId',
        headerName: '機器ID',
        width: 100,
      },
      {
        field: 'moduleName',
        headerName: '解析プログラム',
        width: 150,
        valueGetter: (params: GridValueGetterParams<Analyzation>) => {
         const moduleName = params.row.moduleName;
          return moduleName
        },
      },
      {
        field: 'version',
        headerName: 'バージョン',
        width: 150,
        valueGetter: (params: GridValueGetterParams<Analyzation>) => {
          const analyzerVersionName = params.row.analyzerVersionName
          return analyzerVersionName
        },
      },
      {
        field: 'measureStartDate',
        headerName: '測定開始日',
        width: 160,
        valueFormatter: (params: GridValueFormatterParams<any>) => {
          return params.value ? dayjs(params.value).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
      },
      {
        field: 'firstAnalyzeRequestDate',
        headerName: '登録日（初回解析）',
        width: 170,
        valueFormatter: (params: GridValueFormatterParams<any>) => {
          return params.value ? dayjs(params.value).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
      },
      {
        field: 'lastAnalyzeRequestDate',
        headerName: '再解析依頼日',
        width: 170,
        valueFormatter: (params: GridValueFormatterParams<any>) => {
          return params.value ? dayjs(params.value).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
      },
    ];
    if(!isInputPatientName){
      personalAnalyzationColumns = personalAnalyzationColumns.filter((column) => column.field !== 'patientName')
    }
    if(!clinic?.isAllowedFFTDownload){
      personalAnalyzationColumns = personalAnalyzationColumns.filter((column) => column.field !== 'fftFileName')
    }
    return personalAnalyzationColumns
  },[]) as  GridColDef<GridValidRowModel>[];
  
  const handleSortModelChange = (newSortModel: GridSortItem[]) => {
    setSortModel(newSortModel as GridSortModel); // // ソートモデルの変更を反映
  };
  return (
    <Box sx={{ height: '75vh', width: '100%' }}>
      <StyledDataGrid
        checkboxSelection
        columns={columns}
        rows={analyzations}
        onRowSelectionModelChange={(newSelection: any) => {
          setSelectList(newSelection as string[]);
        }}
        onPinnedColumnsChange={(newPinnedState: any) => {
          setPinnedState(newPinnedState);
        }}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        localeText={{ noRowsLabel: 'データがありません' }}
        pinnedColumns={{ left: pinnedState.left, right: pinnedState.right  }}
      />
    </Box>
  )
}

const StyledDataGrid = styled(DataGridPro)({
  border: 'none',
  '.MuiDataGrid-columnHeaders': {
    backgroundColor: '#898989',
    borderRadius: '0px',
    border: 'none',
    color: '#FFF',
    height: '32px !important',
    minHeight: 'auto !important',
  },
  '.MuiDataGrid-virtualScroller': {
  },
  '.MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '.MuiDataGrid-cellContent': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  '.MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold',
    minHeight: 'auto !important',
    
  },
  '.MuiDataGrid-columnHeaderTitleContainer.MuiDataGrid-columnHeader--pinned': {
    backgroundColor: '#898989',// ピン留めしたヘッダーの背景色
    color: 'white',  // ピン留めしたヘッダーのテキスト色
  },
  '.MuiDataGrid-columnHeader--sortable': {
    backgroundColor: '#898989',// ピン留めしたヘッダーの背景色
    color: 'white', 
    height: '32px !important',
    minHeight: 'auto !important',
  },
  '.MuiDataGrid-columnHeadersInner' :{
    height: '32px !important',
  } ,
  '.MuiDataGrid-columnHeaderTitleContainer': {
    height: '32px !important',
  },
  '.MuiDataGrid-columnHeader': {
    backgroundColor: '#898989',// ピン留めしたヘッダーの背景色
    color: 'white', 
  }
})

const FlexWrap = styled('div')`
display: flex;
align-items: center;
cursor: pointer;
`;

interface LinkWrapProps {
  isProcessDone: boolean;
}

const LinkWrap = styled('div')<LinkWrapProps>`
color: ${props => props.isProcessDone ?  '#0077C7' : 'red'};
&:hover {
  text-decoration: ${props => props.isProcessDone ?  'underline' : 'none'};
};
cursor: ${props => props.isProcessDone ?  'pointer' : 'none'};
`;

const StyledMuiLink = styled(MuiLink)`
&& {
  text-decoration: none;
  margin-right: 8px;

  &:hover {
    text-decoration: underline;
  }
}
`;

const Wrapper = styled('div')`
min-width: 60px;
`;
const StylyedRejectMessage = styled('div')`
overflow: hidden;
white-space: pre-line;
max-height: 2.6em;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
`;

