import React, { useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine, Label } from 'recharts';
import Box from '@mui/material/Box';
import { theme } from '../../../provider/ThemeProvider';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { roundToFirstDecimalPlace, roundToSecondDecimalPlace } from '../../../utils/analyzations/roundFunctions';

export type DataPoint = {
  date: string;
  value: number;
};

const CustomizedAxisTick: React.FC<any> = (props) => {
  const { x, y, payload } = props;
  const [date, time, value] = payload.value.split(' ');
  return (
    <g transform={`translate(${x},${y})`}>
    <text x={20} y={0} dy={20} textAnchor="end" fill="#666" fontSize="12px">
        {date}
    </text>
    {/* 2行目のテキストの色とフォントサイズを指定 */}
    <text x={10} y={0} dy={45} textAnchor="end" fill="#F17160" fontSize="14px" fontWeight={"bold"} >
       {(Number(time))}
    </text>
</g>
 
  );
};
const CustomizedAxisTickFixed0: React.FC<any> = (props) => {
  const { x, y, payload } = props;
  const [date, time, value] = payload.value ? payload.value.split(' ') : ["", "", ""];

  return (
    <g transform={`translate(${x},${y})`}>
    <text x={20} y={0} dy={20} textAnchor="end" fill="#666" fontSize="12px">
        {date}
    </text>
    {/* 2行目のテキストの色とフォントサイズを指定 */}
    <text x={10} y={0} dy={45} textAnchor="end" fill="#F17160" fontSize="14px" fontWeight={"bold"} >
       {Number(time)}
    </text>
</g>
 
  );
};



const CustomDot: React.FC<any> = (props) => {
  const { cx, cy } = props;
  return <circle cx={cx} cy={cy} r={6} stroke="#F17160" strokeWidth={2} fill="white" />;
};

const CustomLabel = (props: any) => {
  const { viewBox, width, average, lang } = props;
  const { x, y } = viewBox;
  return (
    <g>
      <circle  cx={x + 800 - 50} cy={y - 20} r={6}  fill="#ABD7F1" />
      <text x={x + 800 - 35} y={y - 15} textAnchor="start" fill="#4B4B4B" style={{fontWeight: 'bold'}}>
        {lang === "ja" ? "同世代平均" : "average"}：{average}
      </text>
    </g>
  );
};

type Props = {
  title: string;
  average: number;
  timeDataList: DataPoint[],
  subTitle: string;
  unit: string;
  lang: 'ja' | 'en';
  className: string;
  range?: number[];
}

const TwoRowsLabelsChart = (props: Props) => {
  const { title, average, timeDataList, subTitle, unit, lang, className, range } = props;
  const formattedData = timeDataList.map(d => ({ ...d, label: `${d.date} ${roundToSecondDecimalPlace(d.value)}` }));
  const minAndMaxValue = useMemo(() => {
    const values = timeDataList.map(d => d.value);
    const tmpValues = [...values, average];
    const rawMin = Math.min(...tmpValues);
    const rawMax = Math.max(...tmpValues);
    return {
      min: Math.floor((rawMin)),
      max: Math.floor((rawMax)),
    };
  }, [timeDataList]);  

  const customizedRange = useMemo(() => {
    const maxTimeDtaList = Math.max(...timeDataList.map(d => d.value));
    const rangeMax = range ? Math.max(...range) : 0;
    if(!range) return []
    if(rangeMax < minAndMaxValue.max) {
      return [...range, minAndMaxValue.max];
    }
    if(maxTimeDtaList > rangeMax) {
      return [...range, roundToFirstDecimalPlace(maxTimeDtaList) as number];
    }
    return range;
  }, []);

  console.log(title, customizedRange)
  return (
    <StyledContainer className={className}>
    <Container>
    <Box
        sx={{
          backgroundColor: "#4B4B4B",
          width: "100%",
          mt: 2,
          mb: 2,
          }}
          display={'flex'}
          >
        <Box>
        <Title variant="body1" sx={{
          py: 1,
          pl: 1.5,
          fontWeight: 700,
          fontSize: '20px',
          color: theme.palette.text.secondary}}>
          {title}
        </Title>
        </Box>
        <Box>
        <SubTitle variant="body1" sx={{
          pt: 0.8,
          pl: 1.5,
          fontWeight: 700,
          fontSize: '12px',
          color: theme.palette.text.secondary}}>
          {subTitle}
        </SubTitle>
        <SubTitle variant="body1" sx={{
          pl: 2,
          fontWeight: 700,
          fontSize: '12px',
          color: theme.palette.text.secondary}}>
          {`${lang === 'ja' ? "単位": "unit"}: ${unit}`}
        </SubTitle>
        </Box>
      </Box>
    <Wrap>
    <ResponsiveContainer width="100%" height={220}>
      <LineChart data={formattedData} margin={{ top: 25, right: 50, left: 30,}}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="label" height={60} tick={["7.Deep Sleep Brainwave Age", "7.熟眠脳波年齢"].includes(title)  ? <CustomizedAxisTickFixed0/> : <CustomizedAxisTick />} />
        <YAxis fontSize={14} ticks={customizedRange} domain={[range ? range[0] : 0,  range ? range[range?.length-1] : 0]}/>
        <Tooltip />
        <Line type="linear" dataKey="value" stroke="#828282" dot={<CustomDot />} />
        {average  &&  <ReferenceLine y={average} stroke="#ABD7F1" strokeWidth={3}>
        <Label  content={<CustomLabel average={average} lang={lang} />} position="insideTopLeft" offset={-10}/>
      </ReferenceLine>}
      </LineChart>
    </ResponsiveContainer>
    </Wrap>
    </Container>
    </StyledContainer>
  );
};

export default TwoRowsLabelsChart;

const Wrap = styled('div')`
display: flex;
align-items: center;
border: 1px solid #E5E5E5;
border-radius: 4px;
background-color: #FCFCFC;
padding: 12px 12px 24px 12px
&meida print {

`;

const Container = styled('div')`

`;

const StyledContainer = styled('div')`
  /* ここに通常のスタイルを追加できます */

  &.print-padding-top {
    @media print {
      padding-top: 12px;
    }
  }
`;

const Title = styled(Typography)`
@media print { 
font-size: 16px;
}
`;

const SubTitle = styled(Typography)`
@media print { 
font-size: 8px;
}
`;