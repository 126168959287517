import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

export const BodyTitle = styled(Typography).bind({
  variant: 'h3',
})`
  margin: ${(props: any) => props.theme.spacing(1)}px
    ${(props: any) => props.theme.spacing(1)}px 0;
  // font-weight: 700;
  font-family: ABeeZee;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  //text-align: center;
  color: #4b4b4b;
  white-space: nowrap;
  color: #353535;
`;

export const DocumentTitle = styled(Typography).bind({
  variant: 'h6',
})`
  margin: 0;
  //font-weight: 700;
  word-wrap: break-word;
  width: 300px;
  height: 55px;
  overflow: hidden;
  text-overflow: ellipsis;
  //white-space: nowrap;
  font-family: ABeeZee;
  font-style: normal;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #4b4b4b;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

export const MiniText = styled(Typography).bind({
  variant: 'h6',
})`
  margin: 0;
  //font-weight: 700;
  word-wrap: break-word;
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  //white-space: nowrap;
  font-family: ABeeZee;
  font-style: normal;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #4b4b4b;
  display: -webkit-box;
`;

export const HorizontalBar = styled('div')`
  width: 6px;
  height: 18px;
  background: #529BC5;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 10px;
`;

export const MiniTitleText = styled('div')`
  width: auto;
  height: 17px;
  font-family: 'ABeeZee';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #4B4B4B;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

const Container = styled('div')(() => ({
  display: 'flex',
  alignItems: 'left',
}));

export const MiniTitle = (props: any) => {
  return (
    <>
      <Container>
        <HorizontalBar />
        <MiniTitleText>
          {props.text}
        </MiniTitleText>
      </Container>
    </>
  );
};

export const BodyTitleList = styled(Typography).bind({
  variant: 'h6',
})`
  font-weight: 700;
  font-family: ABeeZee;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  align-items: center;
  //text-align: center;
  color: #4b4b4b;
`;

export const BodyText = styled(Typography).bind({
  variant: 'body1',
}) <{ fontSize?: number, small?: boolean }>`
  margin: ${(props) => props.theme.spacing(1)}px
    ${(props) => props.theme.spacing(0)}px
    ${(props) => props.theme.spacing(2)}px;
  font-weight: 400;
  font-family: ABeeZee;
  font-style: normal;
  font-weight: 500;
  font-size: ${({ fontSize }) => (fontSize || '20px')};
  line-height: 30px;
  color: #4b4b4b;
  white-space: 'pre-wrap';

`;

export const LinkText = styled(Typography).bind({
  variant: 'body1',
}) <{ fontSize?: number, small?: boolean }>`
  margin: ${(props) => props.theme.spacing(1)}px
    ${(props) => props.theme.spacing(0)}px
    ${(props) => props.theme.spacing(2)}px;
  font-weight: 400;
  font-family: ABeeZee;
  font-style: normal;
  font-weight: 500;
  font-size: ${({ fontSize }) => (fontSize || '20px')};
  line-height: 32px;
  color: #529BC5;
  cursor: pointer;
`;

export const GenderText = styled(Typography).bind({
  variant: 'body1',
})`
  margin: 5px 0 0 0;
  font-weight: 400;
  font-family: ABeeZee;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #4b4b4b;
`;

export const BodyTableText = styled(Typography).bind({
  variant: 'body1',
}) <{ small?: boolean }>`
  margin: 0
    ${(props) => props.theme.spacing(1)}px
    0
    ${(props) => props.theme.spacing(2)}px;
  font-weight: 400;
  font-family: ABeeZee;
  font-style: normal;
  font-weight: 500;
  align-items: center;
  font-size: ${({ small }) => (small ? '16px' : '20px')};
  //line-height: 17px;
  color: #4b4b4b;
`;

export const ListContainerText = styled(Typography).bind({
  variant: 'body1',
}) <{ small?: boolean }>`
  font-family: ABeeZee;
  font-style: normal;
  font-weight: 500;
  font-size: ${({ small }) => (small ? '16px' : '18px')};
  top: 0;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #4b4b4b;
`;

export const NameContainerText = styled(Typography).bind({
  variant: 'body1',
}) <{ small?: boolean }>`
  font-family: ABeeZee;
  font-style: normal;
  font-weight: 500;
  font-size: ${({ small }) => (small ? '16px' : '18px')};
  top: 0;
  line-height: 20px;
  margin-top: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #4b4b4b;
`;

export const SubText = styled(Typography).bind({
  variant: 'body1',
}) <{ fontcolor?: "blue" | "white" | "black" | "orange" }>`
  font-size: 16px;
  font-family: ABeeZee;
  font-weight: 500;
  line-height: 24px;
  color: ${({ fontcolor }) => {
    switch (fontcolor) {
      case "black":
        return "#4B4B4B"
      case "white":
        return "#ffffff"
      case "orange":
        return "#DB7E29";
      default:
        return "#8abad6"
    }
  }};
  text-align: left;
  cursor: pointer;
`;

export const TableHeadSubText = styled(Typography).bind({
  variant: 'body1',
}) <{ fontcolor?: "blue" | "white" | "black" | "orange" }>`
  font-size: 16px;
  font-family: ABeeZee;
  font-weight: 500;
  //line-height: 32px;
  color: ${({ fontcolor }) => {
    switch (fontcolor) {
      case "black":
        return "#4B4B4B"
      case "white":
        return "#ffffff"
      case "orange":
        return "#DB7E29";
      default:
        return "#8abad6"
    }
  }};
  text-align: left;
  cursor: pointer;
  margin: ${(props) => props.theme.spacing(1)}px
    ${(props) => props.theme.spacing(1)}px
    ${(props) => props.theme.spacing(1)}px
    ${(props) => props.theme.spacing(2)}px;
`;

export const Bold = styled('span')`
  font-weight: bold;
`;

export const GrayText = styled(Typography).bind({
  variant: 'body1',
}) <{ fontSize?: number, small?: boolean }>`
  margin: ${(props) => props.theme.spacing(1)}px
    ${(props) => props.theme.spacing(0)}px
    ${(props) => props.theme.spacing(2)}px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #898989;
  white-space: 'pre-wrap';
  line-height: 1.1;
`;
