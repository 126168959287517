export const  roundToFourthDecimalPlace = (num: number | undefined) => {
  if(typeof num !== 'number') return num;
  return Math.round(num * 10000) / 10000;
}

export const roundToSecondDecimalPlace = (num: number | undefined) => {
  if (typeof num !== 'number') return num;
  if (num >= 1) {
    return Math.round(num * 10) / 10;
  } else {
    return Math.round(num * 100) / 100;
  }
}

export const roundToFirstDecimalPlace = (num: number | undefined) => {
  if (typeof num !== 'number') return num;
  return Math.ceil(num * 10) / 10;
}