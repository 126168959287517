export const anaylzationColumns = [
  {
    value: "edfFileName",
    label: "EDFファイル名",
    isNum: false,
  },
  {
    value: "status",
    label: "解析状態",
    isNum: false,
  },
  {
    value: "equipmentId",
    label: "機器ID",
    isNum: false,
  },
  {
    value: "patientName",
    label: "受診氏名",
    isNum: false,
  },
  // 追加SW側要望
  {
    value: "affiliationName", // 権限に応じて非表示
    label: "所属名",
    type: "patient",
    isNum: false,
  },
  {
    value: "industryName", // 権限に応じて非表示
    label: "就業状態",
    type: "patient",
    isNum: false,
  },
  {
    value: "occupationName", // 権限に応じて非表示
    label: "職種",
    type: "patient",
    isNum: false,
  },
  {
    value: "patientNumber",
    label: "受診者番号",
    isNum: false,
  },
  {
    value: "address", // 受診者住所
    label: "受診者居住地",
    type: "patient",
    isNum: false,
  },
  {
    value: "age",
    label: "年齢",
    isNum: false,
  },
  {
    value: "gender",
    label: "性別",
    isNum: false,
  },
  {
    value: "firstAnalyzeRequestDate",
    label: "登録日（初回解析）",
    isNum: false,
  },
  {
    value: "measureStartDate",
    label: "測定開始日",
    isNum: false,
  },
  {
    value: "mainDiseasId",
    label: "主疾病分類",
    type: "patient",
    isNum: false,
  }, // 要確認
  {
    value: "disease",
    label: "主疾病",
    type: "patient",
    isNum: false,
  },
  {
    value: "primaryDiseaseFirstVisitDate",
    label: "主疾病初診日",
    type: "patient",
    isNum: false,
  }, // 要確認
  {
    value: "medicineInfo",
    label: "投薬情報",
    type: "patient",
    isNum: false,
  },
  {
    value: "sickHistory",
    label: "病歴",
    type: "patient",
    isNum: false,
  },
  {
    value: "totalJudge",
    label: "総合判定",
    type: "analyzationResult",
    isNum: false,
  }, // 要確認
  { 
    value: "tib", 
    label: "測定記録時間(TIB:分)", 
    type: "analyzationResult" ,
    isNum: false,
  },
  { value: "sl", label: "入眠潜時(SL：分)", type: "analyzationResult" , isNum: false,},
  { value: "n3sl", label: "ノンレム深睡眠潜時：分", type: "analyzationResult", isNum: false, },
  { value: "rsl", label: "レム睡眠潜時：分", type: "analyzationResult" , isNum: false,},
  { value: "spt", label: "睡眠時間(SPT：分)", type: "analyzationResult" , isNum: false,},
  { value: "tst", label: "全睡眠時間(TST：分)", type: "analyzationResult", isNum: false, },
  {
    value: "n1StageTotalMin",
    label: "ステージ合計時間ノンレム1：分",
    type: "analyzationResult",
    isNum: true,
  },
  {
    value: "n1StageRatio",
    label: "ステージ割合ノンレム1：％",
    type: "analyzationResult",
    isNum: true,
  },
  {
    value: "n2StageTotalMin",
    label: "ステージ合計時間ノンレム2：分",
    type: "analyzationResult",
    isNum: true,
  },
  {
    value: "n2StageRatio",
    label: "ステージ割合ノンレム2：％",
    type: "analyzationResult",
    isNum: true,
  },
  {
    value: "n3StageTotalMin",
    label: "ステージ合計時間ノンレム3：分",
    type: "analyzationResult",
    isNum: true,
  },
  {
    value: "n3StageRatio",
    label: "ステージ割合ノンレム3：％",
    type: "analyzationResult",
    isNum: true,
  },
  {
    value: "rStageTotalMin",
    label: "ステージ合計時間レム：分",
    type: "analyzationResult",
    isNum: true,
  },
  {
    value: "rStageRatio",
    label: "ステージ割合レム：％",
    type: "analyzationResult",
    isNum: true,
  },
  {
    value: "wStageTotalMin",
    label: "ステージ合計時間覚醒：分",
    type: "analyzationResult",
    isNum: true,
  },
  {
    value: "wStageRatio",
    label: "ステージ割合覚醒：％",
    type: "analyzationResult",
    isNum: true,
  },
  {
    value: "sptWIndex",
    label: "SPTにおける中途覚醒 覚醒指数：回",
    type: "analyzationResult",
    isNum: true,
  },
  {
    value: "sptWakeResponseParEpoch",
    label: "SPTにおける覚醒反応時間/エポック：秒",
    type: "analyzationResult",
    isNum: true,
  },
  {
    value: "sptTotalContinuousWTime",
    label: "SPTにおける中途覚醒 連続覚醒時間の総和：分",
    type: "analyzationResult",
    isNum: true,
  },
  {
    value: "sptWaso",
    label: "SPTにおける中途覚醒 総覚醒時間(WASO：分)",
    type: "analyzationResult",
    isNum: true,
  },
  {
    value: "beforeW2hWIndex",
    label: "起床前2時間覚醒 覚醒指数：回",
    type: "analyzationResult",
    isNum: true,
  },
  {
    value: "beforeSptWakeResponseParEpoch",
    label: "起床前2時間における覚醒反応時間/エポック：秒",
    type: "analyzationResult",
    isNum: true,
  },
  {
    value: "beforeW2hTotalContinuousWTime",
    label: "起床前2時間覚醒 連続覚醒時間の総和：分",
    type: "analyzationResult",
    isNum: true,
  },
  {
    value: "beforeW2hWaso",
    label: "早朝覚醒割合：%",
    type: "analyzationResult",
    isNum: true,
  },
  {
    value: "firstSleepCycle",
    label: "第1睡眠周期：分",
    type: "analyzationResult",
  },
  {
    value: "firstSleepDeviation",
    label: "第1睡眠周期 対平均比率",
    type: "analyzationResult",
  },
  {
    value: "secondSleepCycle",
    label: "第2睡眠周期：分",
    type: "analyzationResult",
  },
  {
    value: "secondSleepDeviation",
    label: "第2睡眠周期 対平均比率",
    type: "analyzationResult",
  },
  {
    value: "thirdSleepCycle",
    label: "第3睡眠周期：分",
    type: "analyzationResult",
  },
  {
    value: "thirdSleepDeviation",
    label: "第3睡眠周期 対平均比率",
    type: "analyzationResult",
  },
  {
    value: "fourthSleepCycle",
    label: "第4睡眠周期：分",
    type: "analyzationResult",
  },
  {
    value: "fourthSleepDeviation",
    label: "第4睡眠周期 対平均比率",
    type: "analyzationResult",
  },
  {
    value: "fifthSleepCycle",
    label: "第5睡眠周期：分",
    type: "analyzationResult",
  },
  {
    value: "fifthSleepDeviation",
    label: "第5睡眠周期 対平均比率",
    type: "analyzationResult",
  },
  {
    value: "sixthSleepCycle",
    label: "第6睡眠周期：分",
    type: "analyzationResult",
  },
  {
    value: "sixthSleepDeviation",
    label: "第6睡眠周期 対平均比率",
    type: "analyzationResult",
  },
  {
    value: "averageSleepCycle",
    label: "平均睡眠周期：分",
    type: "analyzationResult",
    isNum: true,
  },
  {
    value: "averageDeviation",
    label: "平均睡眠周期 対平均比率",
    type: "analyzationResult",
    isNum: true,
  },
  { value: "seTib", label: "睡眠効率(TST/TIB)：％", type: "analyzationResult",  isNum: true, },
  { value: "seSpt", label: "睡眠効率(SPT/TST)：％", type: "analyzationResult" ,  isNum: true,},
  {
    value: "firstSleepCycleDeltaPow",
    label: "第1睡眠周期の総δパワー値：μV²",
    type: "analyzationResult",
    isNum: true,
  },
  {
    value: "firstSleepCycleTime",
    label: "第1睡眠周期時間(REMは除く)：分",
    type: "analyzationResult",
    isNum: true,
  },
  {
    value: "firstSleepCycleDeltaPowParMin",
    label: "第1睡眠周期のδパワー値/ 1分あたり：μV²/分",
    type: "analyzationResult",
    isNum: true,
  },
  {
    value: "totalNonremPow",
    label: "SPTのδパワー値：μV²",
    type: "analyzationResult",
    isNum: true,
  },
  {
    value: "firstSleepCycleDeltaPowParToalDeltaPow",
    label: "SPTのδパワー値/1分あたり：μV²/分",
    type: "analyzationResult",
    isNum: true,
  },
  {
    value: "totalNonremDeltaPowParMin",
    label: "第1睡眠周期/睡眠時間：％",
    type: "analyzationResult",
    isNum: true,
  },
  {
    value: "matureSleepBrainwaveAge",
    label: "熟眠脳波年齢：歳",
    type: "analyzationResult",
  }, // 要確認
   // 以下うつ病の時だけCSVに表示する
   {
    value: "calculatedRejectItemList-1",
    label: "1_AS15-No_アルファ＋シグマ_紡錘波_平均個数_ノンレム期_8-14Hz_0.5-2.5秒_15uV以上_個数/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },

  {
    value: "calculatedRejectItemList-2",
    label: "2_AS15-Dr_アルファ＋シグマ_紡錘波_平均持続時間_ノンレム期_8-14Hz_0.5-2.5秒_15uV以上_持続時間/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-3",
    label: "3_AS20-No_アルファ＋シグマ_紡錘波_平均個数_ノンレム期_8-14Hz_0.5-2.5秒_20uV以上_個数/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-4",
    label: "4_AS20-Dr_アルファ＋シグマ_紡錘波_平均持続時間_ノンレム期_8-14Hz_0.5-2.5秒_20uV以上_持続時間/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-5",
    label: "5_AS30-No_アルファ＋シグマ_紡錘波_平均個数_ノンレム期_8-14Hz_0.5-2.5秒_30uV以上_個数/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-6",
    label: "6_AS30-Dr_アルファ＋シグマ_紡錘波_平均持続時間_ノンレム期_8-14Hz_0.5-2.5秒_30uV以上_持続時間/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-7",
    label: "7_AS-Pw_アルファ＋シグマ_平均パワー値_ノンレム期_8-14Hz_uV/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-8",
    label: "8_AS-PwR_アルファ＋シグマ_平均パワー値比_ノンレム期/レム期_8-14Hz_比",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },{
    value: "calculatedRejectItemList-9",
    label: "9_A-PkNo_アルファ_ピーク_平均個数_ノンレム期_8-12Hz_個数/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-10",
    label: "10_A-Pw_アルファ_平均パワー値_ノンレム期_8-12Hz_uV/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-11",
    label: "11_A-Pw+Ft_アルファ_平均パワー値Filter_ノンレム期_8-12Hz_uV/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-12",
    label: "12_A-PwR_アルファ_平均パワー値比_ノンレム期/レム期_8-12Hz_比",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-13",
    label: "13_A-PwR+Ft_アルファ_平均パワー値比-Filter_ノンレム期/レム期_8-12Hz_比",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-14",
    label: "14_D-Pw_デルタ_平均パワー値_ノンレム期_0.5-2Hz_uV/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-15",
    label: "15_D-PwR_デルタ_平均パワー値比_ノンレム期/レム期_0.5-2Hz_比",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-16",
    label: "16_MD-Pw_ミドルデルタ_平均パワー値_ノンレム期_1.0-2Hz_uV/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-17",
    label: "17_MD-PwR_ミドルデルタ_平均パワー値比_ノンレム期/レム期_1.0-2Hz_比",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-18",
    label: "18_B-Pw_ベータ_平均パワー値_ノンレム期_18-35Hz_uV/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-19",
    label: "19_B-Pw+Ft_ベータ_平均パワー値-Filter_ノンレム期_18-35Hz_uV/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-20",
    label: "20_B-PwR_ベータ_平均パワー値比_レム期/ノンレム期_18-35Hz_比",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-21",
    label: "21_B-PwR+Ft_ベータ_平均パワー値比-Filter_レム期/ノンレム期_18-35Hz_比",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-22",
    label: "22_HD-Pw_ハイデルタ_平均パワー値_ノンレム期_2-4Hz_uV/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-23",
    label: "23_HD-PwR_ハイデルタ_平均パワー値比_ノンレム期/レム期_2-4Hz_比",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-24",
    label: "24_T-Pw_シータ_平均パワー値_ノンレム期_4-8Hz_uV/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-25",
    label: "25_T-Pw+Ft_シータ_平均パワー値-Filter_ノンレム期_4-8Hz_uV/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-26",
    label: "26_T-PwR_シータ_平均パワー値比_ノンレム期/レム期_4-8Hz_比",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-27",
    label: "27_T-PwR+Ft_シータ_平均パワー値比-Filter_ノンレム期/レム期_4-8Hz_比",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-28",
    label: "28_LT-Pw_ローシータ_平均パワー値_ノンレム期_4-6Hz_uV/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-29",
    label: "29_LT-PwR_ローシータ_平均パワー値比_ノンレム期/レム期_4-6Hz_比",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-30",
    label: "30_HT-Pw_ハイシータ_平均パワー値_ノンレム期_6-8Hz_uV/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-31",
    label: "31_HT-Pw+Ft_ハイシータ_平均パワー値-Filter_ノンレム期_6-8Hz_uV/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-32",
    label: "32_HT-PwR_ハイシータ_平均パワー値比_ノンレム期/レム期_6-8Hz_比",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-33",
    label: "33_HT-PwR+Ft_ハイシータ_平均パワー値比-Filter_ノンレム期/レム期_6-8Hz_比",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-34",
    label: "34_LA-Pw_ローアルファ_平均パワー値_ノンレム期_8-10Hz_uV/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-35",
    label: "35_LA-Pw+Ft_ローアルファ_平均パワー値-Filter_ノンレム期_8-10Hz_uV/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-36",
    label: "36_LA-PwR_ローアルファ_平均パワー値比_ノンレム期/レム期_8-10Hz_比",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-37",
    label: "37_LA-PwR+Ft_ローアルファ_平均パワー値比-Filter_ノンレム期/レム期_8-10Hz_比",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-38",
    label: "38_HA-Pw_ハイアルファ_平均パワー値_ノンレム期_10-12Hz_uV/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-39",
    label: "39_HA-PwR_ハイアルファ_平均パワー値比_ノンレム期/レム期_10-12Hz_比",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-40",
    label: "40_S-Pw_シグマ_平均パワー値_ノンレム期_12-18Hz_uV/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-41",
    label: "41_S-PwR_シグマ_平均パワー値比_ノンレム期/レム期_12-18Hz_比",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-42",
    label: "42_LS-Pw_ローシグマ_平均パワー値_ノンレム期_12-15Hz_uV/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-43",
    label: "43_LS-PwR_ローシグマ_平均パワー値比_ノンレム期/レム期_12-15Hz_比",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-44",
    label: "44_HS-Pw_ハイシグマ_平均パワー値_ノンレム期_15-18Hz_uV/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-45",
    label: "45_HS-PwR_ハイシグマ_平均パワー値比_ノンレム期/レム期_15-18Hz_比",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-46",
    label: "46_LB-Pw_ローベータ_平均パワー値_ノンレム期_18-25Hz_uV/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-47",
    label: "47_LB-Pw+Ft_ローベータ_平均パワー値-Filter_ノンレム期_18-25Hz_uV/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-48",
    label: "48_LB-PwR_ローベータ_平均パワー値比_レム期/ノンレム期_18-25Hz_比",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-49",
    label: "49_LB-PwR+Ft_ローベータ_平均パワー値比-Filter_レム期/ノンレム期_18-25Hz_比",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-50",
    label: "50_HB-Pw_ハイベータ_平均パワー値_ノンレム期_25-35Hz_uV/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-51",
    label: "51_HB-Pw+Ft_ハイベータ_平均パワー値-Filter_ノンレム期_25-35Hz_uV/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-52",
    label: "52_HB-PwR_ハイベータ_平均パワー値比_レム期/ノンレム期_25-35Hz_比",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-53",
    label: "53_HB-PwR+Ft_ハイベータ_平均パワー値比-Filter_レム期/ノンレム期_25-35Hz_比",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-54",
    label: "54_ALL-Pw_全帯域_平均パワー値_ノンレム期_0.5-45Hz_uV/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-55",
    label: "55_ALL-Pw+Ft_全帯域_平均パワー値-Filter_ノンレム期_0.5-45Hz_uV/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-56",
    label: "56_ALL-PwR_全帯域_平均パワー値比_ノンレム期/レム期_0.5-45Hz_比",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-57",
    label: "57_ALL-PwR+Ft_全帯域_平均パワー値比-Filter_ノンレム期/レム期_0.5-45Hz_比",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-58",
    label: "58_デルタ帯域(0.5～2.0[Hz])での山の時間長[秒](振幅60～450uV)_ノンレム期_時間長/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-59",
    label: "59_ミドルデルタ(1.0～2.0[Hz])での山の時間長[秒](振幅60～450uV)_ノンレム期_時間長/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-60",
    label: "60_ハイデルタ(2.0～4.0[Hz])での山の時間長[秒](振幅60～450uV)_ノンレム期_時間長/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-61",
    label: "61_シータ帯域(4.0～8.0[Hz])での山の時間長[秒](振幅30～200uV・フィルターあり)_ノンレム期_時間長/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-62",
    label: "62_ローシータ帯域(4.0～6.0[Hz])での山の時間長[秒](振幅30～200uV)_ノンレム期_時間長/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-63",
    label: "63_ハイシータ帯域(6.0～8.0[Hz])での山の時間長[秒](振幅30～200uV・フィルターあり)_ノンレム期_時間長/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-64",
    label: "64_アルファ帯域(8.0～12.0[Hz])での山の時間長[秒](振幅20～200uV・フィルターあり)_ノンレム期_時間長/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-65",
    label: "65_ローアルファ帯域(8.0～10.0[Hz])での山の時間長[秒](振幅20～200uV・フィルターあり)_ノンレム期_時間長/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-66",
    label: "66_ハイアルファ帯域(10.0～12.0[Hz])での山の時間長[秒](振幅20～200uV)_ノンレム期_時間長/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-67",
    label: "67_シグマ帯域(12.0～18.0[Hz])での山の時間長[秒](振幅20～150uV)_ノンレム期_時間長/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-68",
    label: "68_ローシグマ帯域(12.0～15.0[Hz])での山の時間長[秒](振幅20～150uV)_ノンレム期_時間長/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-69",
    label: "69_ハイシグマ帯域(15.0～18.0[Hz])での山の時間長[秒](振幅20～150uV)_ノンレム期_時間長/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-70",
    label: "70_ベータ帯域(18.0～35.0[Hz])での山の時間長[秒](振幅15～100uV・フィルターあり)_ノンレム期_時間長/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-71",
    label: "71_デルタ帯域(0.5～2.0[Hz])での山の個数(振幅60～450uV)_ノンレム期_個数/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-72",
    label: "72_デルタ帯域(0.5～2.0[Hz])での山の個数(振幅30～50uV)_ノンレム期_個数/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-73",
    label: "73_デルタ帯域(0.5～2.0[Hz])での山の個数(振幅50～200uV)_ノンレム期_個数/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-74",
    label: "74_ミドルデルタ(1.0～2.0[Hz])での山の個数(振幅60～450uV)_ノンレム期_個数/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-75",
    label: "75_ハイデルタ(2.0～4.0[Hz])での山の個数(振幅60～450uV)_ノンレム期_個数/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-76",
    label: "76_シータ帯域(4.0～8.0[Hz]振幅30～200μV)での山の個数_ノンレム期_個数/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-77",
    label: "77_ローシータ帯域(4.0～6.0[Hz]振幅30～200μV)での山の個数_ノンレム期_個数/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-78",
    label: "78_ハイシータ帯域(6.0～8.0[Hz]振幅30～200μV)での山の個数_ノンレム期_F_個数/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-79",
    label: "79_ローアルファ帯域(8.0～10.0[Hz]振幅20～200μV)での山の個数_ノンレム期_F_個数/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-80",
    label: "80_ハイアルファ帯域(10.0～12.0[Hz]振幅20～200μV)での山の個数_ノンレム期_個数/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-81",
    label: "81_シグマ帯域(12.0～18.0[Hz]振幅20～150μV)での山の個数_ノンレム期_個数/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-82",
    label: "82_ローシグマ帯域(12.0～15.0[Hz]振幅20～150μV)での山の個数_ノンレム期_個数/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-83",
    label: "83_ハイシグマ帯域(15.0～18.0[Hz]振幅20～150μV)での山の個数_ノンレム期_個数/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-84",
    label: "84_ベータ帯域(18.0～35.0[Hz]振幅15-100μV)での山の個数（フィルターあり)_ノンレム期_個数/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "calculatedRejectItemList-85",
    label: "85_全帯域(0.5～45.0[Hz])での山の個数(振幅450uV以上・フィルターあり)_ノンレム期_個数/epock",
    type: "analyzationResult-calculatedRejectItemList",
    isNum: true,
  },
  {
    value: "commentCustomerUser",
    label: "会員様からのコメント",
  },
  {
    value: "commentAdminUser",
    label: "スリープウェルからのコメント",
  },
];
