import { useEffect, useCallback } from 'react';
import { useAppDispatch, useTypedSelector } from '../../redux';
import { setLogin, resetLogin } from '../../redux/logined';
import { setIdToken, resetIdToken } from '../../redux/idToken';
import axios, { AxiosError } from 'axios';
import { SleepwellApi } from '@sleepwell_new_platform/common-functions/lib/api';
import { useNavigate } from 'react-router-dom';
import useLocalStorage from '../../hooks/localstorage';
import useAnnouncements from '../../hooks/annoucement/announce';
import { resetHasNotReadNotification, setHasNotReadNotification } from '../../redux/hasNotReadNotification';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
const api = new SleepwellApi(axios);
//import { AdminsRedux } from '../redux/admins/index';

export const AwsResourcesToRedux = () => {
  const { announcements, isLoading } = useAnnouncements();
  
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const logined = useTypedSelector((state) => state.logined);
  const [clinicId] = useLocalStorage('clinicId');
  const [userId] = useLocalStorage('id');
  const { data: readAnnouncementLogs, isLoading: isLoadingReadAnnouncementLogs } = useQuery('readAnnouncementLogs', () =>
  api.getReadAnnouncementLog(userId).then((res) => res)
);

  const setSession = useCallback(
    async (idToken: string) => {
      dispatch(setIdToken(idToken));
      dispatch(setLogin(true));
    },
    [dispatch]
  );

  const observeAuth = useCallback(async () => {
    const name = localStorage?.name;
    const idToken = localStorage?.idToken;
    const refreshToken = localStorage?.refreshToken

    if (!idToken) {
      dispatch(resetIdToken());
      dispatch(setLogin(false));
      return;
    }
    const isIdToken = await api.isIdTokenEnable(idToken).catch((err: AxiosError) => {
      if (err.request.status === 400) {
        navigate('/login');
      }
      return err;
    });
    if (isIdToken) {
      // idToken が有効の場合
      setSession(idToken);
    } else {
      // idTokenが無効の場合、refreshTokenで有効なidTokenを取得する
      const refreshTokenRusult = await api.refreshIdToken(name, refreshToken).catch((err: AxiosError) => {
        console.log(err)
        //return err;
      });
      if (refreshTokenRusult.status === 200) {
        // 最新のidToken をローカルストレージへ再格納
        localStorage.idToken = refreshTokenRusult.data.idToken;
        setSession(refreshTokenRusult.data.idToken);
      } else {
        // refreshToken も（有効期限切れなどで）無効の場合、ログアウト状態にする
        dispatch(resetIdToken());
        dispatch(resetLogin());
        localStorage.clear();
        navigate('/login');
      }
    }
  }, [dispatch, setSession]);

  const observeAnnouce = useCallback(async (clinicId: string, userId: string) => {
    if(isLoadingReadAnnouncementLogs || isLoading) return;
    const response = await Promise.all([
      api.getSystemAnnoucements(clinicId),
      api.getSystemAnnoucementLogs(clinicId, userId),
    ])
    const systemAnnouncements = response[0].data;
    const systemAnnouncementLogs = response[1].data;
    const count = systemAnnouncements.result.Items.filter((announcement) => systemAnnouncementLogs.result.Items.every((log) => log.systemAnnouncementId !== announcement.id ));
    let logIds = readAnnouncementLogs?.map(log => log.announcementId);

    let unmatchingCount = announcements
      .filter(announcement => !logIds?.includes(announcement.id) && announcement.isDeleted !== 1 && (announcement.startAt <= dayjs().valueOf() && dayjs().valueOf() <= announcement.stopAt)).length;

    if(count.length > 0 || unmatchingCount > 0)  {
      dispatch(setHasNotReadNotification(true))
    } else {
      dispatch(resetHasNotReadNotification())
    }
  }, [announcements, isLoadingReadAnnouncementLogs]);

  useEffect(() => {
    observeAuth();
  }, [observeAuth]);

  useEffect(() => {
    if(clinicId && userId && logined) {
      observeAnnouce(clinicId, userId)
    }
  }, [logined, clinicId, userId, announcements]);

  return (
    <>
      {logined ? (
        <>
          {/*<AdminsRedux />*/}
        </>
      ) : null}
    </>
  );
};
