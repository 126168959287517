import { styled } from '@mui/system';
import { AccountIcon, NoticeIcon } from '../atoms/icons/index';
import { Logout } from '../atoms/icons/Logout';
import { HeaderButton } from '../atoms/HeaderButton';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useTypedSelector } from '../../redux';

type Props = {
  clinicName: string;
  username: string;
  noticeCount: number;
};

export const Header = ({ clinicName, username, noticeCount }: Props) => {
  const hasNotReadNotification = useTypedSelector((state) => state.hasNotReadNotification);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigation = useNavigate();
  const logout = () => {
    localStorage.clear();
    navigation('/login')
  };
  const handleGoToResetPassword = () => {
    setAnchorEl(null);
    navigation('/reset-password');
  };
  return (
    <Container>
      <TitleContainer>
        <ClinicText>{clinicName}</ClinicText>
        <NameText>{username}</NameText>
      </TitleContainer>
      <Menu>
        <HeaderButton onClick={() => navigation('/announcements')}>
          <Notice>
            <NoticeIcon />
            {hasNotReadNotification && <NoticeCountChip/>}
          </Notice>
        </HeaderButton>
        <HeaderButton onClick={()=> navigation('/reset-password')}>
          <AccountIcon />
        </HeaderButton>
        <HeaderButton onClick={logout}>
          <Logout />
        </HeaderButton>
      </Menu>
    </Container>
  );
};

const Container = styled('div')({
  backgroundColor: '#529bc5',
  boxSizing: 'border-box',
  color: '#FFF',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  height: '72px',
});

const TitleContainer = styled('div')({
  display: 'flex',
  alignItems:'flex-end',
  padding: '22px 20px',
});

const ClinicText = styled('div')({
  fontSize:'20px',
  fontWeight:'bold',
})

const NameText = styled('div')(() => ({
  fontFamily: 'YuGothic',
  fontSize: '16px',
  marginLeft: '16px'
}));

const Menu = styled('div')({
  display: 'flex',
});

const Notice = styled('div')({
  position: 'relative',
});

const NoticeCountChip = styled('div')({
  position: 'absolute',
  top: '4px',
  right: '0px',
  backgroundColor: '#FF9736',
  borderRadius: '90px',
  width: '12px',
  height: '12px',
});

const StyleHeaderText = styled('div')`
  width: 100%;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;
`;