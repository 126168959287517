import { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import IsLogined from '../../utils/IsLogined';
import { Progress } from '../atoms/Progress';
import AnalyzationRequestPage from './AnalyzationReuestPage';
import AnalyzationsPage from './AnalyzationsPage';
import PersonalAnalyzationsPage from './PersonalAnalyzationsPage';
import NotFoundPage from './NotFoundPage';

const LoginPage = lazy(() => import('./LoginPage'));
const ClinicPage = lazy(() => import('./ClinicPage'));
const ClinicUsersPage = lazy(() => import('./ClinicUsersPage'));
const Patients = lazy(() => import('./Patients'));
const Affiliations = lazy(() => import('./Affiliations'));
const EquipmentRentals = lazy(() => import('./EquipmentRental'));
const SleepAnalytics = lazy(() => import('./SleepAnalytics'));
const DocumentsPage = lazy(() => import('./DocumentsPage'));
const MedicalPartnersPage = lazy(() => import('./MedicalPartnersPage'));
const AnnouncementsPage = lazy(() => import('./AnnouncementsPage'));
const AnnouncementPage = lazy(() => import('./AnnouncementPage'));
const ChangePasswordPage = lazy(() => import('./ChangePasswordPage'));
const DetailPatientPage = lazy(() => import('./DetailPatientPage'));
const DailyInputDetailPage = lazy(() => import('./DailyInputDetailPage'));

const pages = [
  {
    path: '/',
    component: (
      <IsLogined>
        <AnalyzationsPage />
      </IsLogined>)
  },
  {
    path: '/login',
    component: <LoginPage />,
  },
  {
    path: "/clinic",
    component: (
      <IsLogined>
        <ClinicPage />
      </IsLogined>
    )
  },
  {
    path: "/clinicUsers",
    component: (
      <IsLogined>
        <ClinicUsersPage />
      </IsLogined>
    )
  },
  {
    path: "/patients",
    component: (
      <IsLogined>
        <Patients />
      </IsLogined>
    )
  },
  {
    path: '/sleepAnalytics/:id/:id/detail',
    component: (
      <IsLogined>
        <DetailPatientPage />
      </IsLogined>
    ),
    isPubulic: false,
  },
  {
    path: '/sleepAnalytics/:id/:id/detail/:day',
    component: (
      <IsLogined>
        <DailyInputDetailPage />
      </IsLogined>
    ),
    isPubulic: false,
  },
  {
    path: "/affiliations",
    component: (
      <IsLogined>
        <Affiliations />
      </IsLogined>
    )
  },
  {
    path: '/announcements',
    component:
      (
        <IsLogined>
          <AnnouncementsPage />
        </IsLogined>
      ),
  },
  {
    path: '/announcement/:annoucementId',
    component:
      (
        <IsLogined>
          <AnnouncementPage />
        </IsLogined>
      ),
  },
  {
    path: "/equipments",
    component: (
      <IsLogined>
        <EquipmentRentals />
      </IsLogined>
    )
  },
  {
    path: "/sleepAnalytics",
    component: (
      <IsLogined>
        <SleepAnalytics />
      </IsLogined>
    )
  },
  {
    path: '/analyzation-request',
    component:
      (<IsLogined>
        <AnalyzationRequestPage />
      </IsLogined>
      ),
  },
  {
    path: '/analyzations',
    component:
      (<IsLogined>
        <AnalyzationsPage />
      </IsLogined>
      ),
  }, {
    path: "/documents",
    component: (
      <IsLogined>
        <DocumentsPage />
      </IsLogined>
    )
  },
  {
    path: "/medicalPartners",
    component: (
      <IsLogined>
        <MedicalPartnersPage />
      </IsLogined>
    )
  },
  {
    path: "/personal-analyzations/:id",
    component: (
      <IsLogined>
        <PersonalAnalyzationsPage/>
      </IsLogined>
    )
  },
  {
    path: "/reset-password",
    component: (
      <IsLogined>
        <ChangePasswordPage/>
      </IsLogined>
    )
  },
];
export const Pages = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Progress />}>
        <Routes >
          {pages.map((item, index) => (
            <Route key={index} path={item.path} element={item.component} />
          ))}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};
