import { ReactNode, useRef } from 'react';
import MuiDialog from '@mui/material/Dialog';
import { styled } from '@mui/system';
import { ModalCloseIcon } from '../atoms/icons';
import { Breakpoint } from '@mui/material';
import { BodyTitle, LinkText } from '../atoms/Text';
import { PrintIcon } from '../../icons/analyzation';
import { useReactToPrint } from 'react-to-print';
//import { SelectInput } from './Select';
import { AnalyzationStatus } from '@sleepwell_new_platform/common-functions/lib/common';
import JSZip from 'jszip';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import axios from "axios";
import { SleepwellApi } from '@sleepwell_new_platform/common-functions/lib/api';
import { PdfProgress } from '../atoms/Progress';
import { formatDateToYYYYMMDDHHMMSS } from '../../utils/Moment';
const Api = new SleepwellApi(axios);

type Props = {
  open: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode;
  width?: number | string;
  maxWidth?: false | Breakpoint | undefined;
  isShowPrintButton?: boolean;
  fileName?: string;
  isNotMaxWidth?: boolean;
  component?: ReactNode;
  selectAnalyzationList?: Array<any>;
  fftDownload?: boolean;
};

export const Modal = ({ open, onClose, title, width, children, maxWidth }: Props) => {
  return (
    <StyledModal open={open} onClose={onClose} maxWidth={maxWidth ?? 'lg'}>
      <Container width={width}>
        <Title>
          <BodyTitle style={{ fontSize: 24 }}>
            {title}
          </BodyTitle>
          <CloseIcon onClick={onClose} />
        </Title>
        {children}
      </Container>
    </StyledModal>
  );
};

export const EditModal = ({ open, onClose, title, width, children, maxWidth, isShowPrintButton, fileName, component }: Props) => {
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    documentTitle: fileName ?? "",
    content: () => componentRef.current,
  });

  return (
    <StyledModal
      fullWidth={true}
      maxWidth={maxWidth ?? 'xl'}
      style={{
        width: '100%',
      }}
      open={open} onClose={onClose}>
      <Container width={width}>
        <Title>
          <div style={{ display: 'flex', alignItems: "stretch" }}>
            <BodyTitle style={{ fontSize: 24, marginRight: 16 }}>{title}</BodyTitle>
            {component}
          </div>
          <FlexWrap>
            {isShowPrintButton && <FlexWrap style={{ marginRight: 18 }} onClick={() => handlePrint()}><PrintIcon /><LinkText style={{ marginRight: 8, marginLeft: 8 }} fontSize={18}>{'印刷する'}</LinkText></FlexWrap>}
            <CloseIcon onClick={onClose} />
          </FlexWrap>
        </Title>
        <PrintArea ref={componentRef}>
          {children}
        </PrintArea>
      </Container>
    </StyledModal>
  );
};

export const PdfDownloadModal = ({ open, onClose, width, children, maxWidth, selectAnalyzationList, fftDownload = false }: Props) => {
  const componentRef = useRef(null);
  const pdfDownloadHandler = async (selectedAnalyzationList: Array<any>, fftDownload: boolean) => {
    if (!componentRef.current) return;

    /*const selectedAnalyzationList: Array<any> = [];
    selectAnalyzationList.map(async (analyzation) => {
      if (analyzation?.status === AnalyzationStatus.CONFIRMED ||
        analyzation?.status === AnalyzationStatus.WAITING_FOR_CONFIRM ||
        analyzation?.status === AnalyzationStatus.WAITING_FOR_CONFIRM_REPORT) {
        selectedAnalyzationList.push(analyzation);
      }
    });*/
    const formatDateToyyyymmddhhmmss = formatDateToYYYYMMDDHHMMSS(new Date());
    const fftDownloadedFolderName = fftDownload ? 'fft_files_' + formatDateToyyyymmddhhmmss : 'pdf_files_' + formatDateToyyyymmddhhmmss;

    const canvas = await html2canvas(componentRef.current, { scale: 2 });
    const imageQuality = 0.5;
    const imgData = canvas.toDataURL('image/jpeg', imageQuality);
    const canvasImageWidth = canvas.width;
    const canvasImageHeight = canvas.height;
    const pdfWidth = canvasImageWidth + 600; // 余白を追加したPDFの幅
    const pdfHeight = canvasImageHeight; // 余白を追加したPDFの高さ
    const filesData = [];
    let count = 1;
    for (let i = 0; i < selectedAnalyzationList.length; i++) {
      const pdf = new jsPDF('p', 'pt', [pdfWidth, pdfHeight / (2 * selectedAnalyzationList.length)]); // ページ2分割
      if (i === 0) {
        pdf.addImage(imgData, 'JPG', 300, 20, canvasImageWidth, canvasImageHeight);
        pdf.addPage();
        pdf.addImage(imgData, 'JPG', 300, - canvasImageHeight / (2 * selectedAnalyzationList.length), canvasImageWidth, canvasImageHeight);
      } else {
        pdf.addImage(imgData, 'JPG', 300, - canvasImageHeight * ((count + 1) / (2 * selectedAnalyzationList.length)), canvasImageWidth, canvasImageHeight);
        pdf.addPage();
        count++;
        pdf.addImage(imgData, 'JPG', 300, - canvasImageHeight * ((count + 1) / (2 * selectedAnalyzationList.length)), canvasImageWidth, canvasImageHeight);
        count++;
      }
      if (fftDownload) {
        const fftUrl = selectedAnalyzationList[i]?.edfUrl.replace('.EDF', '.txt');
        const fftFileName = fftUrl.split('/').pop();

        const getSignedUrlResponse = await Api.getSignedUrl(
          fftFileName,
          selectedAnalyzationList[i].patientId,
          selectedAnalyzationList[i].clinicId,
          "get"
        );
        const url = getSignedUrlResponse.data.result;
        console.log(url);
        const response = await fetch(url);

        filesData.push({ fileName: `${fftDownloadedFolderName}/` + fftFileName, data: await response.blob() });
      }
      //await pdf.save(selectedAnalyzationList[i].edfFileName.replace(".EDF", "") + '.pdf');
      const pdfBlob = pdf.output('blob'); // 上半分のPDFをBlobとして取得
      filesData.push({ fileName: `${fftDownloadedFolderName}/` + selectedAnalyzationList[i].edfFileName.replace(".EDF", "") + '_report.pdf', data: pdfBlob })
    }

    if (fftDownload || selectedAnalyzationList.length > 1) {
      // JSZipインスタンスを作成
      const zip = new JSZip();
      // zip化するファイルを追加
      filesData.forEach((fileData: any) => {
        zip.file(fileData.fileName, fileData.data);
      });

      // zipファイルを生成
      const zipBlob = await zip.generateAsync({ type: 'blob' });

      // zipファイルをダウンロード
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(zipBlob);
      downloadLink.download = `${fftDownloadedFolderName}.zip`;
      downloadLink.click();
    } else if (selectedAnalyzationList.length === 1) {
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(filesData[0].data);
      downloadLink.download = filesData[0].fileName.split('/').pop() || filesData[0].fileName;
      downloadLink.click();
    }
  };
  setTimeout(async () => {
    if (open && selectAnalyzationList) {
      await pdfDownloadHandler(selectAnalyzationList, fftDownload);
      onClose();
    }
    console.log('処理が完了しました。');
  }, 5000);

  return (
    <StyledModal
      fullWidth={true}
      maxWidth={maxWidth}
      open={open} onClose={onClose}>
      <Container width={width}>
        <PdfProgress message='PDFを生成中です。このままお待ちください' />
        <PrintArea ref={componentRef}>
          {children}
        </PrintArea>
      </Container>
    </StyledModal>
  );
};

export const EditWideModal = ({ open, onClose, title, width, children, maxWidth, isShowPrintButton, fileName, component }: Props) => {
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    documentTitle: fileName ?? "",
    content: () => componentRef.current,
  });
 

  return (
    <StyledModal
      fullWidth={true}
      maxWidth={maxWidth}
      open={open} onClose={onClose}>
      <Container width={width}>
      <Title>
          <div style={{ display: 'flex', alignItems: "stretch" }}>
            <BodyTitle style={{ fontSize: 24, marginRight: 16 }}>{title}</BodyTitle>
            {component}
          </div>
          <FlexWrap>
            {isShowPrintButton && <FlexWrap style={{ marginRight: 18 }} onClick={() => handlePrint()}><PrintIcon /><LinkText style={{ marginRight: 8, marginLeft: 8 }}　fontSize={18}>{'印刷する'}</LinkText></FlexWrap>}
            <CloseIcon onClick={onClose} />
          </FlexWrap>
        </Title>
        <PrintArea ref={componentRef}>
          {children}
        </PrintArea>
      </Container>
    </StyledModal>
  );
};


const StyledModal = styled(MuiDialog)({
  borderRadius: 12,
  width: '100%',
});

const Container = styled('div')<{ width?: string | number }>(({ width }) => ({
  position: 'relative',
  width: width ?? '500px',
  padding: '48px',
}));

const Title = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const TitleText = styled('div')({
  color: '#4B4B4B',
  fontSize: '20px',
  fontWeight: 700,
});


const CloseIcon = styled(ModalCloseIcon)({
  cursor: 'pointer',
});

const FlexWrap = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
});

const PrintArea = styled('div')({
});
