import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/system';
import { useMemo, useState } from 'react';
import { athenPointList, colums, snorePointList } from '../../static/analyticsRequest';
import { AnalyzationState } from '../organisms/AnalyzationRequest';
import dayjs from '../../lib/dayjs';
import { Analyzation, Clinics, Patients } from '@sleepwell_new_platform/common-types';
import { SelectInput } from './Select';
import { ModuleVersion } from '@sleepwell_new_platform/common-types/src/modules';
import { Box, Typography } from '@mui/material';
import { ErrorIcon } from '../../icons/analyzation';

type Row = {
  edfFileName: string,
  equipmentId: string,
  rentalPeriod: string,
  patientNumber: string,
  patientName: string,
  rentalStatus: string,
  analyzatinoProgram: string,
  analyzerVersionId: string,
  snoreQuestion: number,
  athens: number
}

function createData(
  edfFileName: string,
  equipmentId: string,
  rentalPeriod: string,
  patientNumber: string,
  patientName: string,
  rentalStatus: string,
  analyzatinoProgram: string,
  analyzerVersionId: string,
  snoreQuestion: number,
  athens: number
) {
  return { edfFileName, equipmentId, rentalPeriod, patientNumber, patientName, rentalStatus,  analyzatinoProgram,  analyzerVersionId, snoreQuestion, athens} as Row;
}
type Props = {
  analyaztionsState?: AnalyzationState[];
  patients: Patients[];
  clinic: Clinics | null;
  deleteEDFFile: (data: number) => void;
  moduleVersions: ModuleVersion[];
  setAnalyaztionsState: React.Dispatch<React.SetStateAction<AnalyzationState[]>>;
}

export const AnalyzationRequestTable = (props: Props) =>{
  const { analyaztionsState, patients, clinic, deleteEDFFile, moduleVersions, setAnalyaztionsState } = props;
  const rows = useMemo(() => {
    if(!analyaztionsState || analyaztionsState.length === 0) return []
    return analyaztionsState.map((analyaztionState) => {
      const name = analyaztionState.edfFileName;
      const equipmentId = analyaztionState.edfFileName.substring(0, 3);
      const rentalPeriod = `${dayjs(analyaztionState.rentalData?.startAt ?? 0).format('YYYY/MM/DD')} - ${dayjs(analyaztionState.rentalData?.endAt ?? 0).format('YYYY/MM/DD')}`;
      const rentalStatus = analyaztionState.rentalData?.status === 0 ? '返却済' : "貸出中";
      const patient = patients.find((patient) => patient.id === analyaztionState.patientId);
      const patientName = patient ? patient.name : '';
      const patientNumber = patient ? patient.patientNumber : '';
      const systemType = clinic?.isEnableDepressionFunction ? "SEAS-D" : "SEAS-G";
      return createData(
        name, equipmentId, rentalPeriod, patientNumber, patientName, rentalStatus, systemType, analyaztionState.analyzerVersionId, analyaztionState.snoreQuestion , analyaztionState.athens
      )
    });
  }, [analyaztionsState]);


  const numberOfAnalyticsColums = 2 + (clinic?.isEnableAthens ? 1 : 0) + (clinic?.isEnableSnoreQuestion ? 1 : 0);
  const items = useMemo(() => {
    if(!moduleVersions || moduleVersions.length === 0) return [];
    const moduleType = clinic?.isEnableDepressionFunction ? 'depression' : 'sleep'
    const sortedModuleVersions = moduleVersions.sort((a, b) => a.updatedAt > b.updatedAt ? -1 : 1);
    return sortedModuleVersions.filter((moduleVersion) => moduleVersion.isValid && moduleVersion.type === moduleType).map((moduleVersion) => {
      return {
        label: moduleVersion.versionName,
        value: moduleVersion.id
      }
    })
  }, [moduleVersions, clinic]);

  const onChangeElement = (index: number, key: keyof Analyzation, value: string ) => {
    if(!analyaztionsState) return;
    setAnalyaztionsState((currentState: AnalyzationState[]) => {
      const newData = {...currentState[index], [key]: value};
      const newState = currentState.slice();
      newState[index] = newData;
      return newState;
    })
  }
  const errorColSpan = useMemo(() => {
    let count = 9;
    if(!clinic?.isEnableAthens) count--;
    if(!clinic?.isEnableSnoreQuestion) count--;
    return count;
  },[clinic])
  
  return (
    <div>
    {analyaztionsState && analyaztionsState?.length > 0 &&
    <TableContainer component={'div'}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTableCell rowSpan={2}>EDFファイル名</StyledTableCell>
            <StyledTableCell align="left" colSpan={5}>貸出情報</StyledTableCell>
            <StyledTableCell align="left" colSpan={numberOfAnalyticsColums}>解析情報</StyledTableCell>
            <StyledTableCell rowSpan={2} align="center"></StyledTableCell>
          </TableRow>
          <TableRow>
            {colums.map((colum, index) => {
              if(index === 0) return;
              if(colum.value === 'athens' && !clinic?.isEnableAthens || colum.value === 'snoreQuestion' && !clinic?.isEnableSnoreQuestion) return;
              return  <StyledTableCell align="center">{colum.label}</StyledTableCell>
              
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows && rows.length > 0 && rows.map((row, index) => (
            <TableRow
              key={row.edfFileName}
            >
              {colums.map((colum) => {
                const value = row[colum.value as keyof Row];
                const isExist = analyaztionsState[index]?.errorType;
                const isReAnalysis = analyaztionsState[index]?.type === 'reAnalysis' ? true : false;
                if(!isExist){
                  if(!(colum.value === 'athens' && !clinic?.isEnableAthens || colum.value === 'snoreQuestion' && !clinic?.isEnableSnoreQuestion)){
                    if(colum.value === 'analyzerVersionId') {
                      return  <StyledDataTableCell align="center" style={{padding: '2px 6px', width: colum?.width, }}>
                        <SelectInput 
                          title={''} 
                          items={items} 
                          value={value} 
                          width={'140px'}
                          onChange={ (e: any) => {
                            onChangeElement(index, 'analyzerVersionId', e.target.value);
                            const versionName = moduleVersions.find((moduleVersion) => moduleVersion.id === e.target.value)?.versionName;
                            onChangeElement(index, 'analyzerVersionName', versionName ?? "");
                        }}  
                        disabled={!clinic?.isAllowedSelectModule || isReAnalysis}                   
                      /></StyledDataTableCell>
                    } else if(colum.value === 'athens' && clinic?.isEnableAthens ) {
                      return  <StyledDataTableCell align="center">
                        <SelectInput 
                          title={''} 
                          items={snorePointList}
                          value={value} 
                          onChange={ (e: any) => {
                            onChangeElement(index, 'athens', e.target.value);
                        }}  
                        width={'90px'}                      
                      />
                        </StyledDataTableCell>
                    } else if(colum.value === 'snoreQuestion' && clinic?.isEnableSnoreQuestion ){
                      return <StyledDataTableCell align="center">
                      <SelectInput 
                          title={''} 
                          items={snorePointList} 
                          value={value} 
                          onChange={ (e: any) => {
                            onChangeElement(index, 'snoreQuestion', e.target.value);
                        }}  
                        width={'90px'}                       
                      />
                      </StyledDataTableCell>
                    } else {
                      const label = colum.value;
                      const isExistFileName = analyaztionsState[index].isExistFileName;
                      const isNotExistRetanlData = analyaztionsState[index].isNotExistRetanlData;
                      const isExist = (colum.value !== 'edfFileName'  && ( isExistFileName) || (isNotExistRetanlData)) ? true : false;
                      return  <StyledDataTableCell align="center">{value}</StyledDataTableCell>
                    }
                  } 
                } else {
                  if(colum.value === 'edfFileName'){
                    return <StyledDataTableCell align="center">{value}</StyledDataTableCell>
                  }
                  if(colum.value === 'equipmentId'){
                    const analyzationData = analyaztionsState[index]
                    return <StyledDataTableCell align="center" colSpan={errorColSpan}  style={{border: '2px solid red'}}>
                      <ErrorMessageConmpoent message={getMessage(analyzationData?.errorType, analyzationData?.equipmentId, analyzationData?.measureStartDate)}/>
                    </StyledDataTableCell>
                  }
                }
            })}
              <StyledDataTableCell align="center" style={{cursor: 'pointer', fontWeight: 'bold'}}><StyledSpan onClick={()=> deleteEDFFile(index)} >キャンセル</StyledSpan></StyledDataTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}
    </div>
  )
}

const StyledTableCell = styled(TableCell)({
  background: "#828282",
  color: "white",
  fontWeight:  'bold',
  padding: '4px',
  fontSize: 12,
  lineHeight: '1rem',
  letterSpacing: 0,
  border: '1px solid #E2E2E2'
});

const StyledDataTableCell = styled(TableCell)({
  fontFamily: "Roboto",
  border: '1px solid #E2E2E2',
  padding: '2px 6px',
  lineHeight: '1rem',
  fontSize: 12,
  letterSpacing: 0,
  '&.MuiTableRow-root:last-child': {border: '1px solid #E2E2E2', },
});

const StyledSpan = styled('span')({
  color: "#529BC5"
});


const ErrorText = styled(Typography)({
  color: "#F15454",
  fontSize: 12,
  fontFamily: "ABeeZee",
  lineHeight: '32px',
  alignItems: 'center',
})


type MessgaeProps = {
  message: string
}
export const ErrorMessageConmpoent = (props: MessgaeProps ) => {
  const {message} = props;
  return(
    <Box
      display="flex"
      alignItems="center"
    >
    <div style={{marginRight: 8, height: 18}}>
    <ErrorIcon/>
    </div>
    <ErrorText>{message}</ErrorText>
    </Box>
  )
}

// typeに応じたメッセージの修正
// type
// 1: EDFファイルが存在する
// 2: 解析済みのためEDFファイルを解析できない
// 3: 再計算済みのためEDFファイルを解析できない
// 4: 該当期間に貸出情報がない
// 5. 同一のファイル名が存在する

const getMessage = (type: string, equipmentId: string, measureStartDate: number) => {
  switch(type){
    case 'isNoPatient':
      return '該当する患者情報がありません。'
    case 'isPublishedReport':
      return '既にEDFファイルが処理されているため、再解析できません。再度解析が必要な場合は事務局へお問い合わせください'
    case 'isExistFileName':
      return '同じファイル名のEDFファイルが既に登録されています。'
    case 'noExistEqupmentInfo':
      return '該当の解析内容は該当期間に機器貸出情報が存在しません'
    case 'isExitReCalc':
      return '再計算済みのためEDFファイルを解析できません'
    case 'isProcessedProcess':
      return '処理中のためEDFファイルをアップできません';
    case 'isFinishedAnalysis':
      return '解析済みのためEDFファイルを解析できません';
    case 'isNotValidFileName':
        return 'ファイル名が不正です。「.EDF」のみアップロード可能です。';
    case 'isNotValidFileNameFormat':
        return 'ファイル名が不正です。半角英数字と _ のみファイル名として利用可能です。';
    case 'isNoRentalData':
      return `該当期間に貸出情報がないため、EDFファイルを解析できません。(機器ID: ${equipmentId}, 測定開始日時：${dayjs(measureStartDate).format('YYYY/MM/DD HH:mm:ss')})`;
    case 'isExistSameFile':
        return '既にEDFファイルが処理されているため、再解析できません。再度解析が必要な場合は事務局へお問い合わせください';
    case 'isNotReadyForReAnalyze':
      return '同じEDFファイル名で処理が完了していない依頼があります。';
    case 'isDoneReCalc':
        return '事務局での精査済みのため、再解析できません。再度解析が必要な場合は事務局へお問い合わせください';
        default:
      return ''
  } 
}