import { Headline, ResultHeadline, SubHeadline } from '../molecules/Headline';
import { HeadlessTable, MultiColmunsTable } from '../molecules/Table';
import { DeltaPowerTimeChart } from '../molecules/chart/DeltaPowerTimeChart';
import {  Typography } from '@mui/material';
import { SleepwellApi } from "@sleepwell_new_platform/common-functions/lib/api";
import axios from 'axios';
import { useQuery, QueryFunctionContext } from 'react-query';
import { Progress } from '../atoms/Progress';
import { useMemo } from 'react';
import { minToHHMM } from '../../utils/timeFormatter';
import SleepLineChart from '../molecules/chart/SleepLineChart';
import SleepRaderChart from '../molecules/chart/SleepRaderChartA4';
import DeletaSampleChart from '../molecules/chart/DeltaSampleChart';
import { Affiliation, Analyzation, Clinics, Patients, SpecializedMedicalListEnum } from '@sleepwell_new_platform/common-types';
import { convertGlobalToLocalPhoneNumber, convertToYYYYMMDD, roundToFive } from '../../utils/commons';
import { AverageItem } from '@sleepwell_new_platform/common-types/src/averageItems';
import { MedicalPartnersTypeEnum } from '@sleepwell_new_platform/common-functions/lib/admins';
import { GoodSleepCondition } from '../molecules/sleepReport/GoodSleepCondition';
import { specialistTypes } from '../../static/medicalPartner';
import { totalJudgeResultList } from '../../static/report';
import {PatientInfoCard} from "../molecules/report/PatientInfoCard"
import { Spacer } from '../atoms/Spacer';
import { ResultTable } from '../molecules/report/ResultTable';
import {  styled } from '@mui/system';
import dayjs from 'dayjs';
import { roundToSecondDecimalPlace } from '../../utils/analyzations/roundFunctions';
import { prefectureMapList } from '../../static/prefecture';

type Props = {
  analyzationId: string;
  openReportModal: boolean;
  age: number;
  measureStartDate: number;
  patients: Patients[];
  patientId: string;
  clinic: Clinics[] | null;
  averageItems: AverageItem[];
  lang: 'ja' | 'en';
  isImmediateAnalyize: number;
  snoreQuestion?: number;
  type?: "analysis" | "reAnalysis" | "reCalculate",
  analyzation: Analyzation | undefined,
  affiliation: Affiliation | null;
}
type QueryKey = [string, string]; 

export const SleepAnalyticsDocAReport = (props: Props) => {
  // pathのanalyzationIdを取得
  const Api = new SleepwellApi(axios);
  const analyzationId = props.analyzationId;
  const patients = props.patients;
  const patientId = props.patientId;
  const openReportModal = props.openReportModal;
  const measureStartDate = props.measureStartDate;
  const averageItems = props.averageItems;
  const lang = props.lang;
  const snoreQuestion = props.snoreQuestion;
  const age = props.age;
  const type = props.type;
  const analyzation = props.analyzation;
  const affiliation = props.affiliation;
  const clinic = useMemo(() => {
    return analyzation?.clinic;
  }, [analyzation?.clinic]);
  const { data: analyzationResult , isLoading, refetch} = useQuery(['analyzationResults', analyzationId], async (context: QueryFunctionContext<QueryKey>)=>{
    const analyzationIdFromKey = context.queryKey[1];
    if (!analyzationIdFromKey) return;
    const res = await Api.getAnalyzationResult(analyzationIdFromKey);
    return res.data;
    }
  );
  const { data: medicalPartners, isLoading:isLoadingMedicalPartner, error } = useQuery(
    'medicalPartners',
    async () => await Api.getMedicalPartners({
      idToken: localStorage?.idToken
    })
  );
  const { data: customerMedicalPartnerDisabled, isLoading:isLoadingCustomerMedicalPartnerDisabled, error: errorCustomerMedicalPartnerDisabled } = useQuery('customerMedicalPartnerDisabled', async () => {
    if(!clinic) return;
    const res = await Api.getCustomerMedicalPartnerDisabled({
      tableName: 'clinics'
    }); 
    return res;
  });

  const analyzationResultData = useMemo(() => {
    const sortedAnalaizationResult = analyzationResult?.result.Items.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1) ;
    return sortedAnalaizationResult ? sortedAnalaizationResult[0] ?? null : null;
  }, [analyzationResult]);

  const tableHeader2 = useMemo(() => {
    if(lang === 'ja'){
      return ['', '解釈', <>同世代・<br/>同世代の<br/>平均</>, <>あなたの<br/>睡眠</>, '所見'];
    } else {
      return ['', 'Interpretation', 'Average sleep of the same generation, same sex ', 'Your sleep', 'Findings'];
    }
  },[lang]);

  const tableHeader3 = useMemo(() => {
    if(lang === 'ja'){
      return  ['', '病院名', '電話番号', '住所'];
    } else {
      return ['', 'Clinc Name', 'Phone Number', 'Address'];
    }},[lang]);

  const tableRows = useMemo(() => {
    let rows: Array<{title: string, value: JSX.Element | React.ReactNode}> =[]
    if(lang === 'ja'){
      rows =  [
        {title: '睡眠脳波', value: analyzationResultData?.totalJudgeInfo.totalCommentJP},
        {title: 'いびき質問票', value: analyzationResultData?.snoreAnswer?.snoreAnswerJP?.replace("<br/>", '\n')},
        {title: 'アテネ不眠尺度', value: analyzation?.athens},
      ]
    } else {
      rows = [
        {title: 'Sleep EEG', value: analyzationResultData?.totalJudgeInfo.totalCommentEN},
        {title: 'Snore Questionnaire', value: analyzationResultData?.snoreAnswer?.snoreAnswerEN?.replace("<br/>", '\n')},
        {title: 'Athens Insomnia Scale', value: analyzation?.athens},
      ]
    }
    const isEnableSnoreQuestion = analyzation?.clinic?.isEnableSnoreQuestion;
    if(!isEnableSnoreQuestion){
      rows = rows.filter((row) => !(row.title === 'いびき質問票' || row.title === 'Snore Questionnaire'))
    }
    if(!analyzation?.clinic?.isEnableAthens){
      rows = rows.filter((row) => !(row.title === 'アテネ不眠尺度' || row.title === 'Athens Insomnia Scale'))
    }
    return rows;
  },[lang, analyzationResultData, snoreQuestion, clinic]);
  const patientData = useMemo(() => {
    const patientData = patients.find((patient) => patient.id === patientId);
    return patientData
  },[lang]);
  
  const tableRow2 = useMemo(() => {
    const tibScoreComment = analyzationResultData?.tibScoreComment;
    let rows: {value: string, align: "left" | "center" | "right" | "justify" | "inherit" | undefined }[][];
    if(lang === 'ja'){
      rows = [
        [
          { value: '総就床時間', align: 'left' },
          { value: '7時間を中心に、短すぎても長すぎても健康に影響があるとされています', align: 'left' },
          { value: `${String(minToHHMM(analyzationResultData?.tibAverage) ?? '')}`, align: 'right' },
          { value: `${String(minToHHMM(analyzationResultData?.tib) ?? 'ー')}`, align: 'right' },
          { value: tibScoreComment?.commentJP ?? "", align: 'left' }
        ],
        [
          { value: '寝付き', align: 'left' },
          { value: '30分以上かかっていると要注意。毎日、1時間以上かかるようなら専門医に要相談', align: 'left' },
          { value: `${String(minToHHMM(analyzationResultData?.fallSleepAverage) ?? '')}`, align: 'right' },
          { value: `${minToHHMM(analyzationResultData?.sl) ?? 'ー'}`, align: 'right' },
          { value: analyzationResultData?.fallSleepComment?.commentJP ?? "", align: 'left' }
        ],
        [
          { value: '熟眠度', align: 'left' },
          { value: '深い睡眠の時に出現する脳波(デルタ波)の量です。健康であっても年齢とともに減少します。詳しくは熟眠脳波年齢の欄を参照下さい。', align: 'left' },
          { value: `${analyzationResultData?.sleepQualityAverage ?? 'ー'}μV./分`, align: 'right' },
          { value: `${analyzationResultData?.firstNonremDeltaPowerInfo.totalDeltaPowParMin ?? 'ー'}μV./分`, align: 'right' },
          { value: analyzationResultData?.sleepQualityComment?.commentJP ?? "", align: 'left' }
        ],
        [
          { value: '中途覚醒', align: 'left' },
          { value: '10％未満なら問題ありません。20％を超えている場合、毎日同じような状況でしたら専門医にご相談下さい。', align: 'left' },
          { value: `${analyzationResultData?.wStageTotalMinAverage}％`, align: 'right' },
          { value: `${analyzationResultData?.wStageRatio ?? 'ー'}％`, align: 'right' },
          { value: analyzationResultData?.wStageTotalMinComment?.commentJP ?? "", align: 'left' }
        ],
        [
          { value: '睡眠効率', align: 'left' },
          { value: '85％以上あれば問題ありません。70％以下は要注意。', align: 'left' },
          { value: `${analyzationResultData?.seTibAverage}％`, align: 'right' },
          { value: `${analyzationResultData?.seTib ?? 'ー'}％`, align: 'right' },
          { value: analyzationResultData?.seTibComment?.commentJP ?? "", align: 'left' }
        ],
        [
          { value: '睡眠リズム', align: 'left' },
          { value: '60〜120分サイクルで、ノンレム睡眠とレム睡眠が繰り返されます。精神的に疲れていると最初のリズムが短くなったりすることもあります。', align: 'left' },
          { value: String(analyzationResultData?.sleepRismAverage), align: 'right' },
          { value: analyzationResultData?.sleepRism === 0 ?  '0' : analyzationResultData?.sleepRism  ? String(roundToSecondDecimalPlace(analyzationResultData?.sleepRism)) : '-', align: 'right' },
          { value: analyzationResultData?.sleepRismComment?.commentJP ?? "", align: 'left' }
        ]
      ];
    } else {
      rows = [
        [
          { value: 'Time in Bed (TIB)', align: 'left' },
          { value: 'The average length of sleep is 7 hours. Having too much or too little sleep is said to affect your health.', align: 'left' },
          { value: `${String(minToHHMM(analyzationResultData?.tibAverage, "en") ?? '')}`, align: 'right' },
          { value: `${String(minToHHMM(analyzationResultData?.tib, "en") ?? '')}`, align: 'right' },
          { value: tibScoreComment?.commentEN ?? "", align: 'left' }
        ],
        [
          { value: 'Sleep Latency', align: 'left' },
          { value: 'You should take note if it takes longer than 30 minutes. If it takes longer than an hour every day, consult a specialist.', align: 'left' },
          { value: `${String(minToHHMM(analyzationResultData?.fallSleepAverage, "en") ?? '')}`, align: 'right' },
          { value: `${minToHHMM(analyzationResultData?.sl, "en") ?? 'ー'}`, align: 'right' },
          { value: analyzationResultData?.fallSleepComment?.commentEN ?? "", align: 'left' }
        ],
        [
          { value: 'Depth of Sleep', align: 'left' },
          { value: 'This is the volume of brainwaves (delta waves) that occur during deep sleep. This decreases as you age. Please refer to the deep sleep brainwave section for details.', align: 'left' },
          { value: `${analyzationResultData?.sleepQualityAverage ?? 'ー'}μV./min`, align: 'right' },
          { value: `${analyzationResultData?.firstNonremDeltaPowerInfo.totalDeltaPowParMin}μV./min`, align: 'right' },
          { value: analyzationResultData?.sleepQualityComment?.commentEN ?? "", align: 'left' }
        ],
        [
          { value: 'Time Awake During Sleep', align: 'left' },
          { value: 'Moving includes waking, rolling over, grinding teeth, etc. If the percentage exceeds 20% on a daily basis, you should consult a specialist.', align: 'left' },
          { value: `${analyzationResultData?.wStageTotalMinAverage}％`, align: 'right' },
          { value: `${analyzationResultData?.wStageRatio ?? 'ー'}％`, align: 'right' },
          { value: analyzationResultData?.wStageTotalMinComment?.commentEN ?? "", align: 'left' }
        ],
        [
          { value: 'Sleep Efficiency', align: 'left' },
          { value: '85% or more is not problematic. 70% or less needs attention.', align: 'left' },
          { value: `${analyzationResultData?.seTibAverage}％`, align: 'right' },
          { value: `${analyzationResultData?.seTib ?? 'ー'}％`, align: 'right' },
          { value: analyzationResultData?.seTibComment?.commentEN ?? "", align: 'left' }
        ],
        [
          { value: 'Rhythm', align: 'left' },
          { value: 'Periods of non-REM sleep and REM sleep are repeated in 60 to 120 minute cycles. If you are mentally tired the first rhythm may shorten.', align: 'left' },
          { value: String(analyzationResultData?.sleepRismAverage), align: 'right' },
          { value: analyzationResultData?.sleepRism === 0 ?  '0' : analyzationResultData?.sleepRism  ? String(roundToSecondDecimalPlace(analyzationResultData?.sleepRism)) : '-', align: 'right' },
          { value: analyzationResultData?.sleepRismComment?.commentEN ?? "", align: 'left' }
        ]
      ];
    }
    if(age < 19){
      // rowsの最後の配列を削除するす
      rows.pop();
    }
    return rows;
  },[analyzationResultData, lang]);

  const tableRow3 = useMemo(() => {
    const totalJudge = analyzationResultData?.totalJudgeInfo.totalJudge ?? "";
    // 都道府県
    
    const isInputPatientAddress = clinic?.isInputPatientAddress;
    // ランクに応じたレポートの可否
    const specializedMedicalList: string[] = clinic?.specializedMedicalList ?? [];
    const medicalPartnerDisabledIds = customerMedicalPartnerDisabled?.map((medicalPartner) => medicalPartner.medicalPartnerId) ?? [];
    const sortedMedicalPartners = medicalPartners?.filter((medicalPartner) => {
      return medicalPartner.prefecture === analyzation?.prefecture && !medicalPartnerDisabledIds.includes(medicalPartner.id);
    })?.sort((a, b) => (a.index && b.index) && a?.index > b?.index ? 1 : -1);
    const isBlankMedicalPartners = (isInputPatientAddress === "prefecture" && specializedMedicalList?.includes((totalJudge ?? "").toLowerCase())) ? false : true;
   const list:  {value: string, align: "center" | "inherit" | "left" | "right" | "justify" | undefined}[][]= [];
      const colums = [MedicalPartnersTypeEnum.GENERAL_SLEEP_SPECIALIST, MedicalPartnersTypeEnum.SLEEP_APNOEA_SYNDROME, MedicalPartnersTypeEnum.MENTAL_SPECIALIST];
      colums.forEach((column, index) => {
        // ここで英語日本語の対応を
        // ja or en or both
        const medicalPartner = isBlankMedicalPartners ? [] : sortedMedicalPartners?.filter((medicalPartner) => medicalPartner.type === column  && [lang, "both"].includes(medicalPartner.reportLang));
        if(!medicalPartner?.length) {
          if(lang === "ja"){
            list.push([
              { value: `${index+1}.${column}`, align: 'center' },
              { value: "", align: 'center' },
              { value: "", align: 'center' },
              { value: "", align: 'center' }
            ]);
          } else {
            list.push([
              { value: `${index+1}.${specialistTypes.find((doc)=>doc.ja === column)?.en}`, align: 'center' },
              { value: "", align: 'center' },
              { value: "", align: 'center' },
              { value: "", align: 'center' }
            ]);
          }
        } else {
          const maxLength = medicalPartner?.length > 2 ? 2 : medicalPartner?.length;
          for(let i = 0; i < maxLength; i++) {
            const medicalPartnerData = medicalPartner[i];
            if(lang === "ja"){
              list.push([
                { value: `${index+1}.${column}`, align: 'center' },
                { value: medicalPartnerData.clinicName, align: 'center' },
                { value: medicalPartnerData?.phoneNumber ?? "", align: 'center' },
                { value: medicalPartnerData.address, align: 'center' }
              ]);
            } else {
              list.push([
                { value: `${index+1}.${specialistTypes.find((doc)=>doc.ja === column)?.en}`, align: 'center' },
                { value: medicalPartnerData.clinicNameEN, align: 'center' },
                { value: medicalPartnerData?.phoneNumberEN ?? "", align: 'center' },
                { value: medicalPartnerData.addressEN, align: 'center' }
              ]);
            }
          }
        }
      });
    return list.filter((doc) => doc[1].value !== "");
  },[analyzationResultData, medicalPartners, lang, customerMedicalPartnerDisabled]);


  const sleepEvaluation = useMemo(() => {
    const data = [
      {
        subject: lang === 'ja' ? '総就寝時間' : 'Time in Bed(TIB)',
        you: roundToFive(analyzationResultData?.tibScore ?? 0),
        average: 3,
      },
      {
        subject: lang === 'ja' ?  '寝つき' : 'Sleep Latency',
        you: roundToFive(analyzationResultData?.fallSleepScore ?? 0),
        average: 3,
      }, 
      {
        subject: lang === 'ja' ? '熟眠度' : 'Depth of Sleep',
        you: roundToFive(analyzationResultData?.sleepQualityScore ?? 0),
        average: 3,
      },
      {
        subject: lang === 'ja' ?'中途覚醒' : 'Time Awake During Sleep',
        you:  roundToFive(analyzationResultData?.wStageTotalMinScore ?? 0),
        average: 3,
      },
      {
        subject: lang === 'ja' ? '睡眠効率' : 'Sleep Efficiency',
        you:  roundToFive(analyzationResultData?.seTibScore ?? 0),
        average: 3,
      },
      {
        subject: lang === 'ja' ? '睡眠リズム' : 'Rhythm',
        you: analyzationResultData?.sleepRism ? roundToFive(analyzationResultData?.sleepRismScore ?? 0) : 3,
        average: 3,
      },
    ];
    if(age < 19){
      return data.filter((doc) => !(doc.subject === '睡眠リズム' || doc.subject === 'Rhythm' ));
    }
    return data;
  },[analyzationResultData, lang]);


  const reportMark = useMemo(() => {
    const isImmediateAnalyize = props.isImmediateAnalyize;
    const isReCalc  = type === "reCalculate" ? true : false;
    if(isReCalc){
      return "R"
    }
    if(!isImmediateAnalyize){
      return "*"
    }
    return ""
  }, [clinic, type]);

  if(isLoading || isLoadingMedicalPartner || isLoadingCustomerMedicalPartnerDisabled) return <div style={{height: '60vh'}}><Progress width={'calc(100% - 64px);'}/></div>
  return (
    <Container>
      <div style={{marginTop: 40}}>
        <PatientInfoCard
          gender={patientData?.gender ?? ''}
          patientName={patientData?.name ?? ''}
          patientNumber={patientData?.patientNumber ?? 0}
          birthdayMillis={convertToYYYYMMDD(patientData?.birthday)}
          measureStartDate={measureStartDate}
          age={props.age}
          affiliationName={affiliation?.name ?? ""}
          lang={lang}
        />
         <Spacer height={24} />
        </div>
      <ResultHeadline 
        rank={analyzationResultData?.totalJudgeInfo.totalJudge ?? ""} 
        result={lang === 'ja' ? totalJudgeResultList.find((doc)=>doc.rank === analyzationResultData?.totalJudgeInfo.totalJudge)?.labelJP ?? "" : totalJudgeResultList.find((doc)=>doc.rank === analyzationResultData?.totalJudgeInfo.totalJudge)?.labelEN ?? ""} 
        lang={lang}/>
         <Spacer height={16} />
      <HeadlessTable rows={tableRows} />
      <Spacer height={24} />
      <Headline
        title={lang === 'ja' ? "SleepScope 脳波検査結果" : "SleepScope Result of Sleep Inspection"}
      />
      <Spacer height={16} />
      <ResultTable
      lang={lang}
      headers={tableHeader2}
      rows={tableRow2}
      />
      <SleepRaderChart
        sleepEvaluation={sleepEvaluation}
        averageItems={averageItems}
        patientData={patientData}
        matureSleepBrainwaveAge={analyzationResultData?.matureSleepBrainwaveAge ?? 0}
        lang={lang}
        age={age}
        totalDeltaPowParMin={analyzationResultData?.firstNonremDeltaPowerInfo.totalDeltaPowParMin ?? 0}
      />
      {analyzation?.clinic?.specializedMedicalList?.includes(analyzationResultData?.totalJudgeInfo?.totalJudge?.toLowerCase() as SpecializedMedicalListEnum)  && <>
      <Headline
        title={lang === 'ja' ? "睡眠専門医" :"Sleep specialist"}
      />
      <Spacer height={8} />
       <MultiColmunsTable
        headers={tableHeader3}
        rows={tableRow3}
      />
      </>}
       <div style={{marginTop: 16}}>
      {lang === 'ja' ? <Typography style={{fontSize: 15, color: "#898989"}}>{`${reportMark}${clinic?.nameJP ?? ""}　電話番号: ${clinic?.phoneNumberJP ? convertGlobalToLocalPhoneNumber(clinic?.phoneNumberJP) : ""}　住所: ${clinic?.prefecture ? clinic?.prefecture : ""}${clinic?.address ?? ""}`}</Typography> :
       <Typography style={{fontSize: 15, color: "#898989"}}>{`${reportMark}${clinic?.nameEN ?? ""}　phoneNumber: ${clinic?.phoneNumberEN ? clinic?.phoneNumberEN : ""}　address: ${clinic?.addressEn ?? ""} ${clinic?.prefecture ? prefectureMapList.find((doc) => doc.label === clinic?.prefecture)?.enLabel  : ""}`}</Typography>}
      </div>
      <PageBreak />
       {/* <Headline
        title={lang === 'ja' ?"デルタ波の量（第一周期の1分あたりの量）と年齢推移" : "delta power volume(delta power at 1st period/min) and age transition"}
      />
      <DeletaSampleChart
        averageItems={averageItems}
        patientData={patientData}
        lang={lang}
        matureSleepBrainwaveAge={analyzationResultData?.matureSleepBrainwaveAge ?? 0}
      /> */}
       <Spacer height={16} />
        <Headline
          title={lang === 'ja' ?"睡眠経過図：睡眠時間における睡眠段階の経過推移" : "Hypnogram:Transitions in Sleep Stages"}
        />
        <Spacer height={8} />
        <SleepLineChart
          analyzationResultData={analyzationResultData}
          openReportModal={openReportModal}
          measureStartDate={measureStartDate}
          lang={lang}
          height={310}
          isScale={true}
        />
      <Headline
        title={lang === 'ja' ?"デルタパワー経過図：睡眠時間におけるデルタ波の量（パワー量）推移" : "Delta Power Chronogram:change of delta power volume during sleep period"}
      />
      <DeltaPowerTimeChart 
        analyzationResultData={analyzationResultData}
        age={age  ?? 0}
        measureStartDate={measureStartDate}
        lang={lang}
        height={320}
      />
      <MarginUp lang={lang}>
        <Headline
          title={lang === 'ja' ?"健康づくりのための睡眠ガイド2023 / 健康づくりのための睡眠指針の改訂に関する検討会" : "A Guide of sleep 2023 for your health / Study Group on Revision of Sleep Guidelines for Health Promotion"}
        />
        <Spacer height={8} />
        <GoodSleepCondition lang={lang} small/>
      </MarginUp>
    </Container>
  );
};

const Container = styled('div')`
  @media print {
    width: 90%;
    margin: 0 auto;
    box-sizing: border-box;
  }
`

const PageBreak = styled('div')`
  break-before: page;
  @media print {
    padding-top: 20px;
  }
`

const MarginUp = styled("div")<{lang: "en" | "ja"}>`
@media print {
  margin-top: ${({lang})=> lang === "en"?"-40px": "-40px"};
}
`