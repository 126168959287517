import axios from 'axios';
import { GetPatientsResponse } from "@sleepwell_new_platform/common-types/src/api";
import { Patients } from "@sleepwell_new_platform/common-types";
//import dayjs from '../../../lib/dayjs';
import { SleepwellApi, clinicEndopoint } from '@sleepwell_new_platform/common-functions/lib/api';
import { convertToGlobalPhoneNumber } from '../../../utils/commons';
const api = new SleepwellApi(axios);

//export const clinicEndopoint = process.env.SLEEPWELL_API_GATEWAY_HOST || "https://yv561wssl6.execute-api.ap-northeast-1.amazonaws.com/v1";
export const patientsEndpoint = `${clinicEndopoint}/clinics/patients`

export const getPatients = async (clinicId: string) => {
  const idToken = localStorage.getItem('idToken');
  return await axios.get<GetPatientsResponse>(patientsEndpoint,
    {
      params: {
        clinicId
      },
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${idToken}`,
      },
    });
}

export const postPatients = async (patients: Patients) => {
  const result = await api.createUser({
    ...patients,
    affiliationId: patients.affiliationId || undefined,
    phoneNumber: convertToGlobalPhoneNumber(patients.phoneNumber!),
    idToken: localStorage.idToken,
    isPatientUserCreatedByEmail: patients?.phoneNumber ? false : true
  })
  console.log(result);
}

export const putPatients = async (patients: Patients, checkPhoneNumberResult?: "changePhoneNumber" | "notChangePhoneNumber" | "deletePhoneNumber" | "createPhoneNumber") => {
  const idToken = localStorage.getItem('idToken');
  console.log(checkPhoneNumberResult)
  // if(patients.phoneNumber && patients && checkPhoneNumberResult && ["changePhoneNumber", "deletePhoneNumber", "createPhoneNumber"].includes(checkPhoneNumberResult)){
  // await api.updateCognitoUser({
  //   userId: patients.id,
  //   newPhoneNumber: convertToGlobalPhoneNumber(patients.phoneNumber!),
  //   email: patients.email,
  //   checkPhoneNumberResult: checkPhoneNumberResult,
  // }).catch((error) => {
  //   console.error(`Failed to update patient with id ${patients.id}:`, error);
  //   throw error;
  // });
  // }
  return await axios.put(patientsEndpoint, {
    ...patients,
    phoneNumber: convertToGlobalPhoneNumber(patients.phoneNumber!)
    //updatedAt: dayjs()
  },
    { headers: { "Content-type": "text/plain", 
    'Authorization': `Bearer ${idToken}`
  } });
}

export const deletePatients = async (selectedRowIds: string[], patients: Patients[]) => {
  const result = selectedRowIds.map((id) => {
    const p = patients.find((p) => p.id === id);
    if (p) {
      const deletePromise = api.deleteUser({
        tableName: 'clinics/patients',
        id: p.id,
        phoneNumber: p.phoneNumber!,
        idToken: localStorage.idToken
      })
      .catch((error) => {
        console.error(`Failed to delete patient with id ${p.id}:`, error);
        return { success: false, id: p.id, error };
      });
      
      // Note: deletePromise is still a Promise and will be settled in the future
      return deletePromise;
    } else {
      console.warn(`No patient found with id ${id}`);
      return Promise.resolve({ success: false, id, error: 'No patient found' });
    }
  });
  
  // Await all the delete operations to complete
  return await Promise.all(result);
};

export const updatePatiensReadOnly = async (selectedRowIds: string[], patients: Patients[]) => {
  const result = await Promise.all(
    selectedRowIds.map(async (id) => {
      const p = patients.find((p) => p.id === id)
      if (p) {
        const res = await putPatients({ ...p, readOnly: true })
      }
    })
  );

  return await result;
}
